.maintainance-container {
  text-align: center;
  padding-top: 200px;
}

.maintainance-container img {
  max-width: 200px;
  margin-bottom: 41px;
}

.maintainance-container h2 {
  color: #ed3833;
  font-size: 32px;
  font-weight: bold;
}

.maintainance-container h3 {
  font-size: 18px;
  color: #00515a;
}
