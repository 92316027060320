.modal-content {
  border: black 12px solid !important;
  width: 550px;
  background-color: #ffffff;
  padding: 73px 69px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content h1 {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.33;
  text-align: left;
}

.modal-content input {
  height: 50px;
  width: 100%;
  font-family: Montserrat;
  font-size: 1.125em;
  line-height: 1.64;
  text-align: left;
  color: #666666;
  border: none;
  border-bottom: black 2px solid;
}

.modal-content input:focus {
  outline: none;
}

.modal-content .button-group {
  margin-top: 4em;
  /* margin-bottom:4em; */
}

.modal-content button {
  width: 138.4px;
  height: 42.8px;
  border: solid 3px #000000;
  background-color: #000000;
  font-size: 1.125em;
  color: #ffffff;
}

.modal-content .cancel-button {
  background-color: white;
  color: #858585;
  border: white 3px solid;
}

.new-number-otp-box {
  display: flex;
  justify-content: space-between;
  border-bottom: black 2px solid;
}

.new-number-otp-box button {
  align-self: center;
  height: 2.4em;
  width: 68px;
}

.new-number-otp-box input {
  border: none;
}

.user-Message {
  color: red;
  margin-top: 1em;
}

.cross-button {
  position: absolute;
  top: 2em;
  right: 2em;
  height: 1.3em;
  width: 1.3em;
}

.cancel-subscription-box {
  display: flex;
  flex-direction: column;

  padding-bottom: 16px;
  margin-bottom: 24px;
}

.modal-content p {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.4em;
}

.cancel-subscription-box span {
  /* margin-bottom: 1em; */
  font-size: 1.125em;
  /* line-height: 1.56; */
}

.cancel-subscription-box span:last-child {
  color: #ed3833;
  cursor: pointer;
}

#cancel-subscription-button-group {
  margin-top: 0em;
}

@media only screen and (max-width: 600px) {
  .modal {
    margin: 0;
    padding: 0;
  }
  .modal-dialog {
    margin: 0 !important;
    padding: 0;
  }
  .modal-content {
    padding: 35px 20px;
  }
  .modal-content .button-group button {
    width: 50%;
  }
}

.steps {
  margin-bottom: 10px;
  padding-right: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
}
.delete-account-head {
  margin-bottom: 10px;
  padding-right: 10px;
  font-family: Montserrat;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.delete-account-subhead {                 
  margin-bottom: 40px;
  padding-right: 10px;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.delete-account-info {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.text-style-1 {
  color: #ee3048;
  text-decoration: underline;
}

.text-style-1:hover {
  cursor: pointer;
}

.text-style-2 {
  font-weight: 600;
  color: #ee3048;
}

.text-style-3 {
  color: #196cca;
  text-decoration: underline;
}
.text-style-3:hover {
  cursor: pointer;
}

.Rectangle-9565 {
  width: auto;
  height: auto;
  padding: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #f1f2f2;
}

.delete-account-footer {
  margin-top: 20px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.accept-button {
  width: auto;
  height: 72px;
  margin-top: 28px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.4s;
}

.button-text {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  color: #fff;
}

.accept-button:hover {
  background-color: #ed3131;
  cursor: pointer;
}

.Back {
  margin-top: 28px;
  padding-right: 5px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.caution-action {
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ee3048;
}

.otp-caution {
  margin: 9px 4px 40px 6px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.delete-account-info-inlay {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}
