.auth-container {
  text-align: center;
}

.auth-container form {
  max-width: 450px;
  width: 90%;
  margin: auto;
  margin-top: 100px;
}

.auth-container h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 36px;
}

.auth-container h3 {
  font-size: 18px;
}

.auth-container input {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  max-width: 500px;
  font-size: 22px;
}

.auth-container div {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.auth-container button {
  background-color: black;
  border: none;
  color: white;
  width: 100%;
  max-width: 500px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  margin-top: 36px;
}

.auth-red {
  color: #ed3833;
  text-decoration: underline;
  text-decoration-color: red;
}

.auth-blue {
  color: #196cca;
  text-decoration: underline;
  text-decoration-color: blue;
}

.new-account{
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  cursor:pointer;
}

@media (max-width: 600px) {
  .new-account{
    font-size: 16px;
  }
}
