.progress-bar-container{
  text-align: center;
margin-top: 40px;
margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .progress-bar-container{
    margin-top: 20px;
    margin-bottom: 20px;
    }
}