.cp-layout {
  text-align: center;
}
.cp-layout h1 {
  font-size: 34px;
  font-weight: bold;
}
.cp-layout h6 {
  font-size: 18px;
}

.cp-info {
  /* margin-top: 74px; */
  margin-bottom: 34px;
}

.cp-card {
  max-width: 506px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d2d3d4;
  background-color: #fff;
  margin: 8px auto;
  display: grid;
  grid-template-columns: 70% 20% 10%;
  padding: 24px;
  cursor: pointer;
}

.cp-card h1 {
  font-size: 34px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
.cp-card h3 {
  font-size: 26px;
  font-weight: 500;
  text-align: left;
  color: #939598;
  margin: 0;
}
.cp-card p {
  font-size: 18px;
  text-align: right;
  margin: 0;
}

.cp-card h4 {
  font-size: 34px;
  font-weight: 600;
  text-align: right;
  color: #000;
  margin: 0;
}

.cp-card img {
  width: 24px;
  height: 24px;
}

.cp-learn-more {
  font-size: 18px;
  font-weight: 500;
}
.cp-learn-more span {
  color: #196cca;
  text-decoration: underline;
}

.cp-ekyc-message {
  margin: 18px auto 30px;
  max-width: 394px;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .cp-layout h1 {
    max-width: 340px;
    font-size: 24px;
    margin:auto;
  }
  .cp-layout h6 {
    font-size: 14px;
  }

  .cp-info {
    margin-bottom: 24px;
  }

  .cp-card {
    max-width: 340px;
    border: solid 1px #d2d3d4;
    background-color: #fff;
    margin: 8px auto;
    display: grid;
    grid-template-columns: 70% 20% 10%;
    padding: 16px;
  }

  .cp-card h1 {
    font-size: 24px;
  }
  .cp-card h3 {
    font-size: 20px;
  }
  .cp-card p {
    font-size: 14px;
  }
  .cp-card h4 {
    font-size: 24px;
  }
  .cp-card img {
    width: 16px;
    height: 16px;
  }
  .cp-learn-more {
    font-size: 16px;
    width: 220px;
    margin: auto;
  }
  .cp-ekyc-message {
    max-width: 340px;
    font-size: 16px;
  }
}
