.countdown-bar{
  background-color: black;
  color:white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
}
.countdown-bar-content{
  display: flex;
  align-items: center;
  margin:auto;
}

.countdown-bar h5{
  font-size: 16px;
  font-weight: bold;
  color: #fdde4b;
  margin: 0;
  margin-left: 16px;
  width: 30px;
}

.countdown-bar p{
font-size: 16px;
font-weight: 500;
}


.checkout-container{
  width: 656px;
  height: 936px;
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f6f6f6;
  margin: 0px auto 0px;
  text-align: center;
  padding-top:39px;
  margin-bottom:40px;
  padding-bottom:40px;
}

.checkout-container h1{
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
}

.checkout-container h3{
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #196cca;
}

.checkout-container h5{
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #282828;
}

.checkout-container h6{
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #666;
}

.checkout-card{
  border-radius: 4px;
  padding: 14px 16px;
  margin:auto;
  max-width: 496px;
  -webkit-backdrop-filter: blur(22.1px);
  backdrop-filter: blur(22.1px);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}



.checkout-card p,h1,h2,h3,h4,h5,h6{
  margin:0;
}


.checkout-card h1{
  font-size: 20px;
  font-weight: bold;
}

.checkout-card h2{
  font-size: 18px;
  font-weight: bold;
}

.checkout-card h3{
  font-size: 16px;
  font-weight: 600;
  color: #939598;
}

.checkout-card h4{
  font-size: 14px;
  font-weight: bold;
  color: #ee3048;
  cursor: pointer;
  text-decoration: underline;
}

.checkout-card h5{
  font-size: 15px;
  font-weight: 500;
  color: #6d6e71;
}
.checkout-card h6{
  font-size: 16px;
  font-weight: 500; 
  color: black;
}

.checkout-button{
  width: 496px;
  height: 72px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 4px;
  border:none;
  -webkit-backdrop-filter: blur(22.1px);
  backdrop-filter: blur(22.1px);
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  margin-bottom: 18px;
}

.checkout-heading{
  display: flex;
  justify-content:center;
  align-items: center;
  gap: 18px;
  max-width: 496px;
  margin: auto;
}

.checkout-heading img{
  width: 90px;
}

.checkout-input-card{
  width: 496px;
  margin:auto;
}

.checkout-input-card input{
  width: 344px;
  height: 48px;
  padding: 14px 16px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  color: #939598;
  margin-right: 8px;
}

.checkout-input-card button{
  width: 144px;
  height: 48px;
  padding: 14px 45px 15px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #d5d5d5;
  font-size: 16px;
  font-weight: 600; 
  color: #fff;
}

.discount-applied{
  font-size: 15px;
  font-weight: 500;
  color: #00b42a;
}

.success-msg{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #00b42a;
  margin:0;
  margin-top: 6px;
}

.error-msg{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #ea4b4b;
  margin:0;
  margin-top: 6px;
}

.review-container{
width: 656px;
margin: auto;
margin-bottom: 30px;
}

.review-container h1{
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
  margin-bottom: 40px;
}

.review-container h4{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.review-container h2{
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #196cca;
  margin-top: 24px;
  margin-bottom: 16px;
}

.review-container h3{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.review-container h6{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
}

.review-container h5{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 24px;
}

.review-container div{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23939598FF' stroke-width='3' stroke-dasharray='7%2c 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #fff;
  width:'';
  padding: 80px;
  margin-bottom: 24px;
}


@media screen and (max-width: 1000px) {
  .countdown-bar{
    height: auto;
  }
  .countdown-bar-content{
    max-width:623px;
    display: flex;
    align-items: center;
    margin:auto;
  }
  }


@media screen and (max-width: 740px) {
  .countdown-bar{
    background-color: black;
    color:white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 18px;
    padding-right: 24px;
    height: auto;
    width: 100%;
    line-height: 1.16;
    height: 56px;
  }
  
  

  .countdown-bar h5{
    font-size: 16px;
    font-weight: bold;
    color: #fdde4b;
    margin: 0;
    margin-left: 16px;
  }
  
  .countdown-bar p{
    font-size: 12px;
  }

  .checkout-container{
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom:30px;
    padding-bottom:30px;
  }
  
.checkout-container h1{
  font-size: 26px;
}

.checkout-container h3{
  font-size: 14px;
}

.checkout-container h5{
  font-size: 13px;
}

.checkout-container h6{
  font-size: 12px;
}

  
.checkout-card{
  border-radius: 4px;
  padding: 14px 16px;
  /* margin: auto 16px ; */
  max-width: 375px;
}

.checkout-card p,h1,h2,h3,h4,h5,h6{
  margin:0;
}


.checkout-card h1{
  font-size: 16px;
  text-align: left;
}

.checkout-card h2{
  font-size: 16px;
  min-inline-size: max-content;
}

.checkout-card h3{
}

.checkout-card h4{
  font-size: 12px;
}

.checkout-card h5{
  font-size: 15px;
  font-weight: 500;
  color: #6d6e71;
}
.checkout-card h6{
  font-size: 16px;
  font-weight: 500;
}

.checkout-button{
  max-width: 375px;
  height: 54px;
  /* margin: auto 16px; */
  margin-bottom: 0;
  position: fixed;
  top: 93%;
  left: 50%;
  z-index: 100;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}


.checkout-heading{
 max-width: 375px;
margin: auto;
 justify-content: space-between;
}
.checkout-heading img{
  width: 60px;
}

.checkout-input-card{
  max-width: 375px;
  margin:auto;
}

.checkout-input-card input{
  max-width: 344px;
  height: 48px;
  padding: 14px 16px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  color: #939598;
  margin-right: 8px;
}

.checkout-input-card button{
  max-width: 144px;
  height: 48px;
  padding: 14px 16px 15px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #d5d5d5;
  font-size: 16px;
  font-weight: 600; 
  color: #fff;
}


.success-msg{
  font-size: 14px;
  font-weight: 600;
  color: #00b42a;
  margin:0;
  max-width: 375px;
   margin: auto;
    text-align:left;
}

.error-msg{
  font-size: 14px;
  font-weight: 600;
  color: #ea4b4b;
  margin:0;
  max-width: 375px;
  margin: auto;
  text-align:left;
}

.review-container{
width: auto;
margin: auto;
margin-bottom: 90px;
}

.review-container h1{
  font-size: 16px;
  margin-bottom: 24px;
}

.review-container h4{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.review-container h2{
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #196cca;
  margin-top: 24px;
  margin-bottom: 16px;
}

.review-container h3{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.review-container h6{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
}

.review-container h5{
  font-size: 14px;
  margin-top: 16px;
}

.review-container div{
  max-width:343px;
  padding: 16px;
  margin: auto;
  margin-bottom: 16px;
}

}