.GiftPageHead {
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.GiftPageSubHead {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.PageOneHeadSpacing {
  margin-top: 150px;
  margin-bottom: 0;
}

.SubHeadPlanSpacing {
  margin-bottom: 44px;
}

.NoMargin {
  margin: 0;
}

.GiftCardHolder {
  display: grid;
}

.CounterContainer {
  grid-area: counter;
  margin: auto 0;
}

.PlusMinus {
  background: black;
  color: white;
  border: 1px solid black;
  padding: 10px;
}

.PlusMinusIcon {
  width: 14px;
}

.Counter {
  border: 1px solid black;
  padding: 10px;
}

.PlanAmount {
  text-align: right;
  font-size: 24px;
}

.SecondBox {
  margin: auto 0;
  grid-area: amount;
  text-align: left;
}

.PlanDetails {
  grid-area: pd;
  margin: auto 0;
}

.PlanName {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.FreeNumberIncluded {
  font-family: Montserrat;
  font-size: 14px;
  color: #666666;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.CheckMark {
  width: 14px;
}

.Ribbon {
  background: linear-gradient(to bottom, #c1c1c1, #8b8b8b);
  width: 18px;
  grid-area: ribbon;
}

.Yellow {
  background: linear-gradient(to bottom, #fcde9b, #ffb100);
}

.BestValue {
  /* height: 11px; */
  float: right;
  text-align: left;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #d31818;
  width: fit-content;
  padding: 1px 10px;
}

.GiftPlanHolder {
  margin: 16px auto;
  border: solid 1px #e3e3e3;
  max-width: 784px;
  height: 96px;
  display: grid;
  grid-template-columns: 30px 300px 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "ribbon pd amount counter";
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
}

.Total {
  grid-area: t;
  margin: auto 36px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.TotalAmount {
  grid-area: ta;
  margin: auto 36px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
}

.AmountText {
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /* text-align: center; */
}

.InputPadding {
  margin-top: 60px;
}

.TotalContainer {
  margin: 0 auto;
  border: solid 1px #000000;
  max-width: 784px;
  height: 96px;
  display: grid;
  grid-template-columns: "auto auto auto";
  grid-template-areas: "t . ta";
}

.ButtonSpacingPageOne {
  margin-top: 74px;
  margin-bottom: 39px;
}

.ButtonSpacingPageTwo {
  margin-top: 34px;
  margin-bottom: 39px;
}

.GiftCouponBuyButton {
  background-color: #000000;
  color: white;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  width: 235.6px;
  height: 72px;
}

.DisabledButton {
  background-color: #d5d5d5;
}

.SuccessCheckMark {
  width: 92px;
  margin: 20px;
}

.DoNextHeader {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  margin-top: 80px;
  margin-bottom: 40px;
}

.ContainerOne {
  grid-area: one;
}

.ContainerTwo {
  grid-area: two;
}

.ContainerImage {
  float: left;
}

.ContainerText {
  float: right;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  width: 75%;
}

.DoNextContainer {
  display: grid;
  grid-template-columns: 30% auto 5% auto 25%;
  grid-template-areas: ". one . two .";
}

.gift-card-discount-container {
  display: flex;
  max-width: 784px;
  margin: 40px auto 10px auto;
  gap: 16px;
}

.gift-card-discount-input-container {
  color: #939598;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  /* border: none; */
  width: 622px;
  padding: 16px;
  border: solid 1px #e3e3e3;
}

.gift-card-discount-input-container input {
  font-size: 16px;
  font-weight: 500;
  width: 90%;
  border: none;
}

.gift-card-discount-input-container img {
  width: 24px;
  height: 24px;
}

.gift-card-discount-container button {
  background-color: #d5d5d5;
  color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 144px;
  font-weight: 600;
}

.gift-card-discount-message-container {
  margin-bottom: 24px;
}

.gift-card-tnc {
  margin-bottom: 30px;
}

.gift-card-tnc p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #929598;
}

@media screen and (max-width: 600px) {
  .SecondBox {
    width: 120px;
  }
  .PlanAmount {
    float: left;
  }
  .BestValue {
    float: right;
    margin-top: 4px;
    font-size: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .PlanName {
    line-height: 1;
  }
  .GiftPlanHolder {
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    width: 90%;
    grid-template-columns: 30px auto auto auto;
    grid-template-areas: "ribbon amount . counter " "ribbon pd . counter";
  }
  .TotalContainer {
    width: 90%;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
  }
  .GiftCouponBuyButton {
    width: 90%;
    height: 80px;
    font-size: 20px;
  }
  .ButtonSpacingPageOne {
    margin-top: 110px;
    margin-bottom: 24px;
  }
  .ButtonSpacingPageTwo {
    margin-top: 34px;
    margin-bottom: 39px;
  }
  .DoNextContainer {
    grid-template-columns: 5% auto 10%;
    grid-template-rows: auto auto;
    grid-template-areas: ". one ." ". two .";
  }
  .PageOneHeadSpacing {
    margin-top: 0;
  }
  .GiftPageHead {
    font-size: 26px;
    max-width: 340px;
    margin: auto;
  }
  .GiftPageSubHead {
    font-size: 18px;
    max-width: 340px;
    margin: 10px auto 30px auto;
  }
  .GiftCouponBuyButton {
    height: 50px;
  }
  .InputPadding {
    margin-top: 110px;
  }
  .PlanDetails {
    margin: 0;
  }
  .PlanAmount {
    font-size: 18px;
  }
  .PlanName {
    font-size: 18px;
  }
  .FreeNumberIncluded {
    font-size: 10px;
  }
  .Total {
    margin: auto 20px;
  }
  .TotalAmount {
    margin: auto 20px;
  }
  .DoNextHeader {
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .ContainerText {
    font-size: 22px;
  }
  .PlusMinus {
    padding: 6px;
  }
  .Counter {
    padding: 6px;
  }
  .gift-card-discount-container {
    flex-direction: column;
    width: 90%;
    /* display: flex; */
    /* max-width: 784px; */
    /* margin: 40px auto 10px auto; */
    /* gap: 16px; */
  }

  .gift-card-discount-input-container {
    width: 100%;
  }

  .gift-card-discount-input-container input {
    font-size: 16px;
    font-weight: 500;
    width: 90%;
    border: none;
  }

  .gift-card-discount-input-container img {
    width: 24px;
    height: 24px;
  }

  .gift-card-discount-container button {
    width: 100%;
    padding: 12px;
  }

  .gift-card-discount-message-container {
    margin-bottom: 24px;
  }
}
