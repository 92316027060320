/* clamp(MIN, VAL, MAX) */
.title {
  font-size: 32px;
  font-weight: bold;

}

.sub-title {
  font-size: 18px;
}
.form-otp-sent{
  font-size: 18px;
}

.number-type-card-container {
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}

.number-type-card {
  border: 1px solid #d2d3d4;
  display: grid;
  margin: 20px auto;
  max-width: 1042px;
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-columns: 74% 26%;
  cursor: pointer;
}

.number-type-card h1 {
  font-size: 26px;
  font-weight: bold;
}

.number-type-card h2 {
  color: #196cca;
  font-size: 20px;
  max-width: 64%;
}

.number-type-card-section-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.number-type-card-section-two h1 {
  font-size: 34px;
  font-weight: bold;
}

.number-type-card-section-two h2 {
  font-size: 34px;
  margin-bottom: 0;
  font-weight: bold;
  color: black;
  float: right;
}

.number-type-card-inactive {
  cursor: default;
}

.inactive-font1 {
  color: #e2e2e2;
}

.custom-number-price-container {
  width: 205px;
}

.number-starts-from {
  font-size: 18px;
  margin-bottom: 0;
  text-align: right;
}

.number-card-container {
  max-width: 1070px;
  margin: 40px auto;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: center;
  padding: 0;
}

.number-card {
  /* max-width: 521px; */
  padding: 14px 24px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d2d3d4;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 70% 20% 10%;
  cursor: pointer;
}

.number-card h3 {
  font-size: 34px;
  font-weight: bold;
  align-items: flex-end;
  margin: auto 0;
}

.number-card p {
  font-size: 18px;
  margin: 0;
}

.number-card h4 {
  font-size: 34px;
  font-weight: 600;
  margin: 0;
}

.number-card img {
  width: 24px;
  height: 24px;
}

.input-form {
  margin: auto;
  margin-top: 50px;
  max-width: 540px;
  width: 90%;
}

.form-input {
  border: none;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #707070;
  font-size: 26px;
}

.form-input:focus{
  border-bottom: 2px solid black;
}

.t-n-c {
  font-size: 14px;
  color: #666666;
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.form-button-container {
  margin-top: 43px;
  max-width: 400px;
  margin:auto;
  margin-top:70px;
  display: flex;
  justify-content: space-between;
}
.form-extras{
  margin-top: 8px;
  font-size: 14px;
  color:#707070;
}

.form-back-btn {
  width: 188px;
  height: 72px;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 18px;
  border: solid 2px #000000;
  background-color: #ffffff;
  /* margin: 0 15px; */
}

.form-proceed-btn {
  width: 188px;
  height: 72px;
  font-size: 18px;
  font-weight: 600;
  /* margin: 0 15px; */
  color: white;
  padding: 20px 18px;
  border: solid 2px #000000;
  background-color: #000000;
}

.payment-btn {
  width: 100%;
}

.form-btn-icon {
  position: relative;
  top: -2px;
  font-size: 22px;
}

.form-resend-otp {
  margin-left: auto;
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  color: #ed3833;
  padding-top: 8px;
  cursor: pointer;
}

.select-number-type-icon {
  height: 24px;
  width: 24px;
}


.number-card-two {
  width: 343px;
  color: white;
  height: 64px;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: #196cca;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: auto;
}

.number-card-two:hover {
  background-color: #1259A8;
}

.more-card {
  width: 343px;
  color: #196cca;
  fill: #196cca;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 64px;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin: auto;
  /* mix-blend-mode: normal; */
}

.more-card:hover {
  color: #1259A8;
  fill: #1259A8;
  /* mix-blend-mode: normal; */
}

.number-card-section {
  text-align: center;
  margin-top: 80px;
}

.number-card-section-top{
  margin-top: 0;
}

.number-card-section h1 {
  font-size: 34px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 16px;
}

.number-card-section h2 {
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 40px;
}

@media (max-width:1200px) {
  .number-card-container {
    max-width: 700px;
  }
}

@media (max-width: 600px) {
  .title {
margin:auto;
    font-size: 24px;
    width: 80%;
  }

  .form-otp-sent{
    font-size: 14px;
  }

  .sub-title {
    margin: 16px auto 0 auto;
    width: 80%;
    font-size: 14px;
  }

  .number-type-card-container {
    margin-top: 24px;
  }

  .number-type-card {
    width: 94%;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    margin: 8px auto;
    padding: 16px;
  }

  .number-type-card h1 {
    font-size: 16px;
  }

  .number-type-card h2 {
    font-size: 14px;
    max-width: 100%;
  }

  .number-type-card-section-two {
    margin-top: 16px;
  }

  .number-type-card-section-two h2 {
    float: left;
  }

  .select-number-type-icon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 16px;
    left: 8px;
  }

  .number-starts-from {
    font-size: 12px;
    text-align: left;
  }

  .number-card-container {
    margin-top: 24px;
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(auto-fit, minmax(94%, 1fr));
  }

  .number-card {
    width: 94%;
    margin: auto;
    padding: 14px 16px;
  }

  .number-card h3 {
    font-size: 16px;
  }

  .number-card p {
    font-size: 12px;
  }

  .number-card h4 {
    font-size: 16px;
  }

  .number-card img {
    width: 16px;
    height: 16px;
  }

  .input-form {
    width: 90%;
  }

  .form-input {
    font-size: 21px;
  }

  .t-n-c {
    font-size: 14px;
    color: #666666;
    padding-top: 24px;
  }

  .form-button-container {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
  .form-extras{
    font-size: 12px;
  }

  .form-back-btn {
    width: 100%;
    height: 54px;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 18px;
    margin: 4px auto;
    border-radius: 5px;
  }

  .form-proceed-btn {
    width: 100%;
    height: 54px;
    font-size: 16px;
    font-weight: 600;
    margin: 4px auto;
    border-radius: 5px;
    color: white;
    padding: 14px 18px;
  }

  .payment-btn {
    max-width: 340px;
  }

  .form-btn-icon {
    font-size: 16px;
  }

  .form-resend-otp {
    text-align: center;
    font-size: 14px;
    padding-top: 16px;
  }


  .number-card-section {
    text-align: left;
    width: 343px;
    margin: auto;
    margin-top: 54px;
  }

  .number-card-section-top{
    margin-top: 0;
  }

  .number-card-section h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    line-height: 1.13;
    margin-bottom: 4px;
  }

  .number-card-section h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 1.14;
    margin-bottom: 16px;
    color: #939598;
  }

}