.success-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.success-page h1{
  font-size: 34px;
  font-weight: bold;
  color: #196cca;
  margin:0;
  margin-bottom: 10px;
}

.success-page h2{
  font-size: 30px;
  font-weight: 500;
  margin:0;
  margin-bottom: 40px;
}

.success-page-container{
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f6f6f6;
  width: 656px;
  height: 642px;
  align-items: center;
  margin: 0 0 54.8px;
  padding: 80px 125.6px 80px 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-page-container h1{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 45px;
}

.success-page-container div{
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  gap:24px;
}

.success-page-container p{
  margin: 0;
  font-size: 26px;
  font-weight: normal;
  line-height: 1.38;
}

.store-container{
  margin-top:50px;
  width:400px;
}

.to-account{
  width: 320px;
  height: 54px;
  font-size: 18px;
  font-weight: 600;
  /* margin: 0 15px; */
  color: white;
  padding: 12px 18px;
  border: solid 2px #000000;
  background-color: #000000;
  margin-bottom: 50px;
}



@media screen and (max-width: 600px) {
  .success-page{
  }
  
  .success-page h1{
    font-size: 26px;
    margin-bottom: 4px;
  }
  
  .success-page h2{
    font-size: 18px;
    margin-bottom: 24px;
  }
  
  .success-page-container{
    box-shadow: none;
    width: 100%;
    height: 394px;
    margin: 0 0 54.8px;
    padding: 40px 0 40px 0;
  }
  
  .success-page-container h1{
    font-size: 20px;
    margin-bottom: 24px;
  }
  
  .success-page-container div{
   width: 64%;
   margin-bottom: 22px;
   gap:16px;
  }
  
  .success-page-container p{
    margin: 0;
    font-size: 18px;
  }
  
  .store-container{
    margin-top: 20px;
  }
  
  .to-account{
    width: 320px;
    height: 54px;
    font-size: 18px;
    font-weight: 600;
    /* margin: 0 15px; */
    color: white;
    padding: 12px 18px;
    border: solid 2px #000000;
    background-color: #000000;
  }

  .PlayStore{
    width: 149px;
    height: 44px;
  }
  .AppStore{
    width: 124px;
    height: 44px;
  }
}