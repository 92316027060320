.a_Doosra__Account-Info-1 {
  background-color: #ffffff;
  margin-left: 92px;
  margin-right: 92px;
  display: grid;
  grid-template-areas: "info-holder order-history";
  grid-template-columns: minmax(200px, 500px) auto;
  grid-gap: 24px;
  margin-bottom: 50px;
}

.account-info-card-holder {
  display: flex;
  flex-direction: column;
  gap: 24px;
  grid-area: info-holder;
}
.Toastify__toast--success {
  background: green;
}

.Account-Info {
  height: 42px;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  line-height: 2.24;
  margin-bottom: 50px;
  margin-left: 92px;
}

.side-box {
  background-color: #f1f1f1;
  padding: 43px 56px 33px 56px;
  display: flex;
  flex-direction: column;
}

.profile-box-element {
  justify-content: space-between;
}

.edit-img-icon {
  height: 1.125em;
  cursor: pointer;
}

.side-box-element {
  font-family: Montserrat;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.56; */
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  border-bottom: #ccc 1px solid;
  display: flex;
  /* justify-content: space-between; */
  /* border: red 1px solid; */
  /* height: 50px; */
  padding-bottom: 14px;
  margin-bottom: 24px;
}

.side-box h1 {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  border-bottom: black 2px solid;
  align-items: center;
}

.side-box-bold-element {
  font-weight: 600;
}

.doosra-number-box {
  /* grid-column: 1/2; */
  /* grid-row: 2/3; */
  height: fit-content;
}

.kyc-msg-box {
  /* grid-column: 1/2; */
  /* grid-row: 3/4; */
  background-color: #fcd8d7;
  display: flex;
  padding: 18px;
  flex-direction: row;
  align-items: center;
  height: fit-content;
}
.kyc-msg-box p {
  margin: 0;
  padding-left: 18px;
  font-size: 16px;
  max-width: 415px;
}

.profile-box {
  /* grid-column: 1/2; */
  /* grid-row: 4 /5; */
  height: fit-content;
}

.side-box-sub-element {
  padding: 5px 0;
  font-size: 14px;
}

.invite-box {
  /* grid-column: 1/2; */
  /* grid-row: 5/6; */
}

.plan-box {
  /* grid-column: 1/2; */
  /* grid-row: 6/7; */
  height: fit-content;
}

.extra-box {
  /* grid-column: 1/2; */
  /* grid-row: 7/8; */
  height: fit-content;
}

.order-history-box {
  /* grid-column: 2/-1; */
  /* grid-row: 2 / none; */
  grid-area: order-history;
}

.order-table {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.89;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  grid-template-columns: 20% auto 20% 20%;
}
.order-table-header {
  border-bottom: black 2px solid !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.price-column {
  font-weight: 600;
  text-align: right;
  margin-right: 100px;
}

.order-box-element {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.pad-right {
  padding-right: 0.5em;
}

.discounted-payment {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  text-align: right;
  margin-bottom: 12px;
}
.discounted-payment h5 {
  margin: 0;
  line-height: 2;
  font-size: 18px;
}

.discounted-payment p {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
}
.discounted-payment h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.account-info-card {
  background-color: #f1f1f1;
  padding: 43px 56px 33px 56px;
  display: flex;
  flex-direction: column;
}

.account-info-card h1 {
  font-size: 21px;
  font-weight: 600;
  color: #000000;
  align-items: center;
}

.account-info-card hr {
  border-bottom: 2px solid black;
}

.account-info-card h2 {
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  color: #ee3048;
  padding-top: 40px;
  width: fit-content;
}

.account-info-card h5 {
  font-size: 16px;
  color: #555555;
}

@media only screen and (max-width: 1300px) {
  .Account-Info {
    grid-area: accInfo;
  }
  .doosra-number-box {
    grid-area: doosraNo;
  }
  .kyc-msg-box {
    grid-area: kycMsg;
  }
  .kyc-msg-box p {
    max-width: 100%;
  }
  .profile-box {
    grid-area: prof;
  }
  .invite-box {
    grid-area: invite;
  }
  .plan-box {
    grid-area: plan;
    padding: 43px 56px 33px 56px;
  }
  .order-history-box {
    grid-area: hist;
  }
  .extra-box {
    grid-area: extra;
  }
  .account-info-card-holder {
    grid-area: holder;
  }
  .a_Doosra__Account-Info-1 {
    display: grid;
    grid-template-columns: 10% auto 10%;
    grid-template-rows: auto;
    grid-gap: 24px;
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: 10px;
    box-sizing: border-box;
    grid-template-areas: ". holder ." ". hist . ";
  }

  .Account-Info {
    margin-left: 13%;
  }
}

@media only screen and (max-width: 600px) {
  .a_Doosra__Account-Info-1 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 24px 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: 10px;
    box-sizing: border-box;
    /* padding: 10px; */
  }

  .account-info-card-holder {
    width: 100vw;
  }

  .Account-Info {
    text-align: center;
    margin-left: 0;
  }

  .side-box {
    padding: 0;
    height: auto;
    padding: 10px;
    max-width: 100vw;
  }
  .Account-Info {
    /* padding: 15px; */
    margin-left: 0;
  }
  .order-box-element {
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .doosra-number-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }
  .kyc-msg-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }
  .profile-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }

  .invite-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }

  .plan-box {
    grid-column: 1/-1;
    grid-row: auto;
    padding: 10px;
    margin: 0 2%;
  }
  .extra-box {
    grid-column: 1/-1;
    grid-row: auto;
    padding: 10px;
    margin: 0 2%;
  }
  .account-info-card {
    padding: 10px;
    margin: 0 2%;
  }
  .order-history-box {
    grid-column: 1/-1;
    grid-row: auto;
    /* border: red 1px solid; */
  }
  table {
    border: 0;
  }
  table thead {
    display: none;
  }
  table tr {
    margin: 20px 10px;
    display: block;
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    /* border: green 1px solid; */
  }
  table td {
    /* display: block;
        text-align: left; */
    font-size: 13px;
    border-bottom: 1px dotted #ccc;
    display: flex;
    flex-direction: column;
    padding: 0px 0.75em;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:first-child {
    border-bottom: 0;
    font-weight: 600;
  }
  table td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    color: black;
  }
  .price-column {
    text-align: left;
  }
  .discounted-payment {
    text-align: left;
  }
}
