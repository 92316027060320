@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap);
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

* {
  font-family: Montserrat;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ed3833;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mp-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 68px;
  margin-bottom: 30px;
}

.mp-user {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.you-will-get-card {
  width: 420px;
  margin: 5px 4px;
  padding: 34px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
  border: 0.3px solid #d1d3d4;
  background-image: linear-gradient(180deg, #fcfcfc, #fcfcfc);
  position: relative;
  text-align: center;
}

.you-will-get-card h1 {
  margin: 0;
  font-size: 26px;
  font-weight: bold;
}

.you-will-get-card h2 {
  margin: 0;
  font-size: 34px;
  font-weight: 600;
}

.you-will-get-card p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #ed3833;
  text-decoration: underline;
}

.you-will-get-card img {
  width: 57px;
  height: 57px;
  position: absolute;
  left: -30px;
  top: 24%;
}

.code-box-holder {
  margin-top: 32px;
}

.code-box-container {
  width: 100%;
  display: flex;
  margin: 4px auto;
  max-width: 440px;
}
.code-input-container {
  border-radius: 4px;
  border: dashed 1px #707070;
  display: flex;
  align-items: center;
  width: 100%;
}
.code-input-container input {
  border: none;
  margin: 8px;
  width: 100%;
  font-size: 16px;
  color: #939598;
}

.code-input-container img {
  height: 22px;
  margin: 8px;
}
.apply-code {
  width: 120px;
  height: 56px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #939598;
  margin-left: 12px;
}

.apply-code-active {
  background-color: black;
}

.timer-container {
  width: 200px;
  padding: 22px;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timer-container p {
  font-size: 26px;
  color: #666;
  margin: 0;
  margin-right: 8px;
}
.timer-container h2 {
  font-size: 26px;
  font-weight: bold;
  margin: 0;
}

@media (max-width: 600px) {
  .mp-container {
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    text-align: center;
  }

  .mp-user {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  .you-will-get-card {
    max-width: 340px;
    padding: 16px;
    margin: 10px auto;
  }

  .you-will-get-card h1 {
    font-size: 16px;
  }

  .you-will-get-card h2 {
    font-size: 20px;
  }

  .you-will-get-card p {
  }

  .you-will-get-card img {
    width: 28px;
    height: 28px;
    position: absolute;
    left: -15px;
    top: 28%;
  }
  .code-box-container {
    max-width: 340px;
  }
  .code-input-container input {
    font-size: 14px;
  }
  .timer-container {
    width: 140px;
    padding: 12px 16px;
    margin: 10px auto 20px;
  }

  .timer-container p {
    font-size: 18px;
  }
  .timer-container h2 {
    font-size: 18px;
    /* width: 110%; */
  }
}

.Navbar {
  width: 100%;
  background-color: transparent;
  height: 95px;
  list-style-type: none;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.NavbarButton {
  background-color: black;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: white;
display: flex;
justify-content: center;
align-items: center;
  width: 144px;
  height: 52px;
      border-radius: 12px;
    background-color: #ed3833;
  margin-right: 4vw;
  text-align: center;
  cursor: pointer;
}

.NavbarButton:hover {
  color: #ffffff;
  text-decoration: underline;
}

.Logo {
  width:212px;
  height:60px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoBlack {
  padding: 3%;
  width: 20%;
}

.logo {
  width: 80%;
}

.NavbarShadow {
  box-shadow: 0 1px 3px 0 rgba(250, 8, 8, 0.1);
}

@media screen and (max-width: 1000px) {
  .Logo {
    display: block;
    width: 50%;
    height: auto;
    margin: 10px 0;
  }
  .LogoBlack {
    display: block;
    width: 40%;
    height: auto;
  }

  .logo {
    width: 80%;
  }

  .NavbarButton {
    background-color: white;
    font-family: Montserrat;
    font-size: 22px;
    color: black;
  }

}

@media screen and (max-width: 600px) {
  .NavbarButton {
    width: 100px;
  }
}

.modal {
  font-size: 12px;
  border: 1px solid black;
  padding: 5px;
}

.modal>.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.modal>.content {
  width: 100%;
  padding: 10px 5px;
}

.modal>.actions {
  width: 100%;
  /* padding: 10px 5px; */
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.Modal {
  border: 12px solid black;
  background-color: #ffffff;
  padding: 20px 30px;
  margin: 10px;
  max-width: 500px;
  min-height: 400px;
}

.ModalHeading {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: 20px 0;
}

.PlanUpgradeModalHeading {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 20px 0;
}

.PlanUpgradeModalSubHeading {
  width: 375px;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.NoBottomPadding {
  padding-bottom: 0px;
}

.YayDiscount {
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #53b416;
  text-align: center;
}

.ModalBody {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.ModalBodySpacer {
  margin: 45px 0;
}

.OptionBox {
  width: 100%;
  height: 65px;
  padding: 10px 10px;
  margin: 18px 0;
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

.OptionBox img {
  width: 19px;
  margin-left: 10px;
}

.Recommended {
  color: white;
  background-color: red;
  font-size: 8px;
  position: relative;
  top: -20px;
  left: 148px;
}

.WhatYouGet {
  background-color: #f8f8f8;
  /* margin: 0 5%; */
  margin-bottom: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  text-align: left;
  padding: 20px 25px 20px 50px;
}

.WhatYouGet h1 {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.WhatYouGet p {
  margin-bottom: 5px;
}

.WhatYouGetGrid {
  display: grid;
  grid-template-columns: 7% 80%;
  grid-template-rows: auto auto auto auto auto;
}

.WhatYouGetGrid img {
  width: 16px;
  position: relative;
  top: 2px;
}

.ItemSelectedBorder {
  border: 5px solid #ed3833;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  /* align-items: center; */
}

.ItemUnselectedBorder {
  border: 5px solid #d5d5d5;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  /* align-items: center; */
}

.ModalButtonHolder {
  text-align: right;
  padding: inherit;
}

.ModalButtonHolderTwo {
  text-align: right;
  padding: inherit;
  margin: 0 0 20px 0;
}

.PlanUpgradeText {
  /* padding: 0 5%; */
  /* width: 375px; */
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.ModalButton {
  background-color: black;
  color: white;
  padding: 0.75rem 1.25rem;
  border: 0;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.ModalButtonTwo {
  background-color: black;
  color: white;
  padding: 20px 10px;
  border: 0;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  width: 100%;
}

.Invert {
  color: black;
  background-color: white;
}

.ButtonInvert {
  color: black;
  background-color: white;
}

.ModalButton:active,
.ModalButton:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.ModalButtonTwo:active,
.ModalButtonTwo:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.ModalScroll {
  height: 250px;
  overflow: scroll;
}

.ModalScrollLarge {
  max-height: 90vh;
  overflow: scroll;
}

.ModalScrollLarge::-webkit-scrollbar {}

.ModalScrollLarge::-webkit-scrollbar-thumb {}

.ModalScroll::-webkit-scrollbar {
  width: 0.5rem;
}

/* scrollbar track */

.ModalScroll::-webkit-scrollbar-track {
  /* background: #ffffff; */
  /* border: 2px solid black; */
}

/* scrollbar scroll line color*/

.ModalScroll::-webkit-scrollbar-thumb {
  background: #ed3833;
}

.SuccessBox {
  font-size: 18px;
  border: 1px solid #53b416;
  padding: 9px 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.SuccessBoxRightImage {
  margin-right: 20px;
}

.SuccessBoxRightImage img {
  width: 22px;
  position: relative;
  top: -2px;
}

.SuccessBoxLeftImage {
  margin-left: 20px;
}

.SuccessBoxLeftImage img {
  max-width: 18px;
  position: relative;
  top: -2px;
}

.ModalTwo {
  border: 8px solid black;
  background-color: #ffffff;
  /* margin: 10px; */
  width: 500px;
  min-height: 400px;
}

.ModalBodyTwo {
  padding: 0 20px;
}

.HeaderInfoContainer {
  background-color: #ed3833;
  color: #ffffff;
  display: grid;
  grid-template-columns: 20% 50% 30%;
  height: 70px;
}

.InYourCart {
  font-family: Montserrat;
  font-size: 16px;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.69; */
  margin-bottom: 0;
  letter-spacing: normal;
  text-align: left;
}

.PlanTypeInCart {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.HeaderInfoCoupon {}

.HeaderInfoAmount {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
}

.HeaderInfoAmount img {
  width: 14px;
}

.ModalTwoTitleContainer {
  padding: 20px 0;
}

.ModalTwoTitle {
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0;
}

.ModalTwoSubTitle {
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  line-height: 1;
}

.ModalTwoCurentNumber {
  font-family: Montserrat;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  padding: 25px 0;
  margin-bottom: 0;
}

.NumberContentHolder {
  border: 4px solid #d5d5d5;
  cursor: pointer;
  padding: 25px 0;
  margin: 10px 0;
  text-align: center;
}

.NumberContentHolder:hover {
  border: 4px solid #ed3833;
}

.NumberContentHolder h1 {
  margin: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.NumberContentHolder h2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.NumberContentHolder img {
  width: 13px;
}

.NumberContentHolderTwo {
  border: 4px solid #d5d5d5;
  padding: 15px 0;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 47% 22% 31%;
  grid-template-areas: "one two counter";
}

.UnavailableHolder {
  border: 4px solid #d5d5d5;
  color: grey;
  cursor: default;
}

.UnavailableHolder:hover {
  border: 4px solid #d5d5d5;
}

.SelectNumberNum {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: 20px;
  grid-area: "one";
}

.SelectNumeberPrice {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin: auto 0;
  grid-area: "two";
}

.SelectNumeberPrice img {
  width: 14px;
}

.DontLikeThisnumberContainer {
  padding: 30px 43px 29px;
  margin: 15px 0;
  background-color: #f8f8f8;
  text-align: center;
}

.DontLikeThisnumberContainer h1 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.DontLikeThisnumberContainer h2 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.DontLikeThisnumberContainer p {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ed3833;
  cursor: pointer;
  text-decoration: underline;
}

.CheckoutModalSplitHolder {
  margin: 24px 0 20px 5px;
  margin: 5% 5%;
  padding: 20px 24px;
  background-color: #f8f8f8;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 20px 0;
  grid-template-rows: auto auto;
}

.CheckoutModalTotalHolder {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  margin: 5% 10%;
}

.ChooseSpecialNumberOption {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ed3833;
  text-decoration: underline;
}

.CouponNotApplied {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
}

.CouponApplied {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
  color: #00b415;
}

.ModalTimerHolder {
  width: 125px;
  /* margin: 2ch; */
  margin: 5px auto;
  background-color: #f4f4f4;
  padding: 5px;
}

.ModalTimerText {
  font-family: Montserrat;
  font-size: 16px;
  margin: 5px;
  color: #666666;
}

.ModalTimerTime {
  /* float: right;
  width: 2vw; */
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  margin: 5px;
  color: black;
  display: inline-block;
}

.ModalPasswordCheckContainer {
  background-color: #f2f2f2;
  /* width: 400px; */
  /* min-width: 25vw; */
  margin: 20px auto;
  padding: 20px;
}

.ModalPasswordCheckContainer h1 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.ModalPasswordCheckContentContainer {
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: auto auto auto auto;
}

.ModalPasswordCheckContentContainer p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.ProductSelectCapsuleHolder {
  text-align: center;
  margin: 10px 0 30px 0;
}

.ProductSelectCapsule {
  padding: 7px 20px 6px;
  margin: 10px;
  border-radius: 30px;
  border: solid 2px #d1d3d4;
  background-color: #ffffff;
  cursor: pointer;
}

.ProductSelectCapsuleHeader {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.17; */
  letter-spacing: normal;
  text-align: center;
  color: #d1d3d4;
}

.ProductSelcetCapsuleHeaderPro {
  font-style: italic;
}

.CapsuleSelected {
  background-color: #ed3833;
  border: solid 2px #ed3833;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.CapsuleSelectedText {
  color: white;
}

.CapsuleSelectedSubText {
  color: black;
}

.ModalPasswordCheckContentContainer img {
  width: 18px;
}

.BuyMinutesModalBody {
  padding: 20px 40px 0px;
}

.ApplyButton {
  margin-top: 0;
}

.RupeeIcon {
  position: relative;
  top: -1px;
}

@media screen and (max-width: 1700px) {
  .ModalScrollLarge {
    height: 90vh;
    overflow: scroll;
  }

  .ModalScrollLarge::-webkit-scrollbar {
    width: 0.5rem;
  }

  .ModalScrollLarge::-webkit-scrollbar-thumb {
    background: #ed3833;
  }
}

@media screen and (max-width: 600px) {
  .Modal {
    border: 12px solid black;
    background-color: #ffffff;
    padding: 20px 30px;
    margin: 10px;
    width: 96%;
    height: 600px;
    overflow: scroll;
  }

  .PlanUpgradeModalHeading {
    font-size: 20px;
    padding: 0;
  }

  .PlanUpgradeModalSubHeading {
    width: 230px;
    font-size: 14px;
  }

  .ItemUnselectedBorder {
    border: 9px solid #d5d5d5;
    font-size: 18px;
  }

  .ItemSelectedBorder {
    border: 9px solid #ed3833;
    font-size: 18px;
  }

  .DiscountCodeBoxTwo {
    margin: auto 0;
    padding: 10px 15px;
  }

  .DiscountCodeInputTwo {
    max-width: 235px;
  }

  .ButtonTwo {
    padding: 15px 25px;
    width: 46%;
    margin: 0px;
    margin-top: 10px;
  }

  .Recommended {
    top: -16px;
    left: 94px;
  }

  .SuccessBox {
    font-size: 12px;
    border: 1px solid #53b416;
    /* padding: 9px 5px; */
  }

  .SuccessBoxRightImage {
    margin-right: 5px;
  }

  .SuccessBoxRightImage img {
    width: 18px;
  }

  .SuccessBoxLeftImage {
    margin-left: 5px;
  }

  .SuccessBoxLeftImage img {
    width: 18px;
  }

  .AdjustOptnForDiscountedPrices {
    font-size: 13px;
  }

  .ModalTwo {
    width: 100%;
    margin: auto;
  }

  .ModalTwoTitle {
    font-size: 20px;
  }

  .ModalTwoSubTitle {
    font-size: 16px;
  }

  .NumberContentHolder h1 {
    font-size: 16px;
  }

  .NumberContentHolder h2 {
    font-size: 16px;
  }

  .SelectNumberNum {
    font-size: 16px;
    margin: auto 0;
  }

  .SelectNumeberPrice {
    font-size: 16px;
  }

  .InYourCart {
    font-size: 12px;
  }

  .PlanTypeInCart {
    font-size: 14px;
  }

  .HeaderInfoAmount {
    font-size: 16px;
  }

  .DontLikeThisnumberContainer {
    padding: 21px;
  }

  .DontLikeThisnumberContainer h1 {
    font-size: 16px;
  }

  .DontLikeThisnumberContainer h2 {
    font-size: 16px;
  }

  .DontLikeThisnumberContainer p {
    font-size: 16px;
  }

  .ModalButtonTwo {
    font-size: 14px;
  }

  .ModalTwoCurentNumber {
    font-size: 32px;
    padding: 10px 0;
  }

  .WhatYouGet {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
  }

  .WhatYouGetGrid img {
    top: 0px;
  }

  .WhatYouGet h1 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .WhatYouGet p {
    margin-bottom: 8px;
  }

  .WhatYouGetGrid {
    grid-template-columns: 9% 80%;
  }

  .ProductSelectCapsule {
    padding: 7px 8px 6px;
    margin: 5px;
    border-radius: 30px;
    cursor: pointer;
  }

  .ProductSelectCapsuleHeader {
    font-size: 14px;
  }

  .ModalScrollLarge {
    /* height: 650px; */
    height: 90vh;
    overflow: scroll;
  }

  .ModalScrollLarge::-webkit-scrollbar {
    width: 0.5rem;
  }

  .ModalScrollLarge::-webkit-scrollbar-thumb {
    background: #ed3833;
  }

  .BuyMinutesModalBody {
    padding: 0 20px;
  }

  /* .RupeesIcon {
    width: 15px;
    position: relative;
    top: -1px;
  } */
  .OptionBox img {
    width: 15px;
    margin-left: 10px;
  }

  .ApplyButton {
    width: 100%;
  }
}
.countdown-bar{
  background-color: black;
  color:white;
  font-size: 16px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 56px;
}
.countdown-bar-content{
  display: flex;
  align-items: center;
  margin:auto;
}

.countdown-bar h5{
  font-size: 16px;
  font-weight: bold;
  color: #fdde4b;
  margin: 0;
  margin-left: 16px;
  width: 30px;
}

.countdown-bar p{
font-size: 16px;
font-weight: 500;
}


.checkout-container{
  width: 656px;
  height: 936px;
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f6f6f6;
  margin: 0px auto 0px;
  text-align: center;
  padding-top:39px;
  margin-bottom:40px;
  padding-bottom:40px;
}

.checkout-container h1{
  font-size: 34px;
  font-weight: bold;
  font-style: normal;
}

.checkout-container h3{
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  color: #196cca;
}

.checkout-container h5{
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  color: #282828;
}

.checkout-container h6{
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: #666;
}

.checkout-card{
  border-radius: 4px;
  padding: 14px 16px;
  margin:auto;
  max-width: 496px;
  -webkit-backdrop-filter: blur(22.1px);
  backdrop-filter: blur(22.1px);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
}



.checkout-card p,h1,h2,h3,h4,h5,h6{
  margin:0;
}


.checkout-card h1{
  font-size: 20px;
  font-weight: bold;
}

.checkout-card h2{
  font-size: 18px;
  font-weight: bold;
}

.checkout-card h3{
  font-size: 16px;
  font-weight: 600;
  color: #939598;
}

.checkout-card h4{
  font-size: 14px;
  font-weight: bold;
  color: #ee3048;
  cursor: pointer;
  text-decoration: underline;
}

.checkout-card h5{
  font-size: 15px;
  font-weight: 500;
  color: #6d6e71;
}
.checkout-card h6{
  font-size: 16px;
  font-weight: 500; 
  color: black;
}

.checkout-button{
  width: 496px;
  height: 72px;
  font-size: 18px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 4px;
  border:none;
  -webkit-backdrop-filter: blur(22.1px);
  backdrop-filter: blur(22.1px);
  box-shadow: 0 10px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  margin:auto;
  margin-bottom: 18px;
}

.checkout-heading{
  display: flex;
  justify-content:center;
  align-items: center;
  grid-gap: 18px;
  gap: 18px;
  max-width: 496px;
  margin: auto;
}

.checkout-heading img{
  width: 90px;
}

.checkout-input-card{
  width: 496px;
  margin:auto;
}

.checkout-input-card input{
  width: 344px;
  height: 48px;
  padding: 14px 16px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  color: #939598;
  margin-right: 8px;
}

.checkout-input-card button{
  width: 144px;
  height: 48px;
  padding: 14px 45px 15px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #d5d5d5;
  font-size: 16px;
  font-weight: 600; 
  color: #fff;
}

.discount-applied{
  font-size: 15px;
  font-weight: 500;
  color: #00b42a;
}

.success-msg{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #00b42a;
  margin:0;
  margin-top: 6px;
}

.error-msg{
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: #ea4b4b;
  margin:0;
  margin-top: 6px;
}

.review-container{
width: 656px;
margin: auto;
margin-bottom: 30px;
}

.review-container h1{
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
  margin-bottom: 40px;
}

.review-container h4{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.review-container h2{
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #196cca;
  margin-top: 24px;
  margin-bottom: 16px;
}

.review-container h3{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.review-container h6{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
}

.review-container h5{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 24px;
}

.review-container div{
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23939598FF' stroke-width='3' stroke-dasharray='7%2c 11' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  background-color: #fff;
  width:'';
  padding: 80px;
  margin-bottom: 24px;
}


@media screen and (max-width: 1000px) {
  .countdown-bar{
    height: auto;
  }
  .countdown-bar-content{
    max-width:623px;
    display: flex;
    align-items: center;
    margin:auto;
  }
  }


@media screen and (max-width: 740px) {
  .countdown-bar{
    background-color: black;
    color:white;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 18px;
    padding-right: 24px;
    height: auto;
    width: 100%;
    line-height: 1.16;
    height: 56px;
  }
  
  

  .countdown-bar h5{
    font-size: 16px;
    font-weight: bold;
    color: #fdde4b;
    margin: 0;
    margin-left: 16px;
  }
  
  .countdown-bar p{
    font-size: 12px;
  }

  .checkout-container{
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom:30px;
    padding-bottom:30px;
  }
  
.checkout-container h1{
  font-size: 26px;
}

.checkout-container h3{
  font-size: 14px;
}

.checkout-container h5{
  font-size: 13px;
}

.checkout-container h6{
  font-size: 12px;
}

  
.checkout-card{
  border-radius: 4px;
  padding: 14px 16px;
  /* margin: auto 16px ; */
  max-width: 375px;
}

.checkout-card p,h1,h2,h3,h4,h5,h6{
  margin:0;
}


.checkout-card h1{
  font-size: 16px;
  text-align: left;
}

.checkout-card h2{
  font-size: 16px;
  min-inline-size: -webkit-max-content;
  min-inline-size: -moz-max-content;
  min-inline-size: max-content;
}

.checkout-card h3{
}

.checkout-card h4{
  font-size: 12px;
}

.checkout-card h5{
  font-size: 15px;
  font-weight: 500;
  color: #6d6e71;
}
.checkout-card h6{
  font-size: 16px;
  font-weight: 500;
}

.checkout-button{
  max-width: 375px;
  height: 54px;
  /* margin: auto 16px; */
  margin-bottom: 0;
  position: fixed;
  top: 93%;
  left: 50%;
  z-index: 100;
  /* bring your own prefixes */
  transform: translate(-50%, -50%);
}


.checkout-heading{
 max-width: 375px;
margin: auto;
 justify-content: space-between;
}
.checkout-heading img{
  width: 60px;
}

.checkout-input-card{
  max-width: 375px;
  margin:auto;
}

.checkout-input-card input{
  max-width: 344px;
  height: 48px;
  padding: 14px 16px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  color: #939598;
  margin-right: 8px;
}

.checkout-input-card button{
  max-width: 144px;
  height: 48px;
  padding: 14px 16px 15px;
  border-radius: 4px;
  border: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #d5d5d5;
  font-size: 16px;
  font-weight: 600; 
  color: #fff;
}


.success-msg{
  font-size: 14px;
  font-weight: 600;
  color: #00b42a;
  margin:0;
  max-width: 375px;
   margin: auto;
    text-align:left;
}

.error-msg{
  font-size: 14px;
  font-weight: 600;
  color: #ea4b4b;
  margin:0;
  max-width: 375px;
  margin: auto;
  text-align:left;
}

.review-container{
width: auto;
margin: auto;
margin-bottom: 90px;
}

.review-container h1{
  font-size: 16px;
  margin-bottom: 24px;
}

.review-container h4{
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.review-container h2{
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #196cca;
  margin-top: 24px;
  margin-bottom: 16px;
}

.review-container h3{
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.review-container h6{
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
}

.review-container h5{
  font-size: 14px;
  margin-top: 16px;
}

.review-container div{
  max-width:343px;
  padding: 16px;
  margin: auto;
  margin-bottom: 16px;
}

}
.progress-bar-container{
  text-align: center;
margin-top: 40px;
margin-bottom: 40px;
}

@media screen and (max-width: 600px) {
  .progress-bar-container{
    margin-top: 20px;
    margin-bottom: 20px;
    }
}
/* clamp(MIN, VAL, MAX) */
.title {
  font-size: 32px;
  font-weight: bold;

}

.sub-title {
  font-size: 18px;
}
.form-otp-sent{
  font-size: 18px;
}

.number-type-card-container {
  list-style-type: none;
  padding: 0;
  margin-top: 40px;
  margin-bottom: 40px;
}

.number-type-card {
  border: 1px solid #d2d3d4;
  display: grid;
  margin: 20px auto;
  max-width: 1042px;
  border-radius: 6px;
  padding: 24px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  display: grid;
  grid-template-columns: 74% 26%;
  cursor: pointer;
}

.number-type-card h1 {
  font-size: 26px;
  font-weight: bold;
}

.number-type-card h2 {
  color: #196cca;
  font-size: 20px;
  max-width: 64%;
}

.number-type-card-section-two {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.number-type-card-section-two h1 {
  font-size: 34px;
  font-weight: bold;
}

.number-type-card-section-two h2 {
  font-size: 34px;
  margin-bottom: 0;
  font-weight: bold;
  color: black;
  float: right;
}

.number-type-card-inactive {
  cursor: default;
}

.inactive-font1 {
  color: #e2e2e2;
}

.custom-number-price-container {
  width: 205px;
}

.number-starts-from {
  font-size: 18px;
  margin-bottom: 0;
  text-align: right;
}

.number-card-container {
  max-width: 1070px;
  margin: 40px auto;
  display: grid;
  grid-gap: 20px;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  justify-content: center;
  padding: 0;
}

.number-card {
  /* max-width: 521px; */
  padding: 14px 24px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d2d3d4;
  background-color: #ffffff;
  display: grid;
  grid-template-columns: 70% 20% 10%;
  cursor: pointer;
}

.number-card h3 {
  font-size: 34px;
  font-weight: bold;
  align-items: flex-end;
  margin: auto 0;
}

.number-card p {
  font-size: 18px;
  margin: 0;
}

.number-card h4 {
  font-size: 34px;
  font-weight: 600;
  margin: 0;
}

.number-card img {
  width: 24px;
  height: 24px;
}

.input-form {
  margin: auto;
  margin-top: 50px;
  max-width: 540px;
  width: 90%;
}

.form-input {
  border: none;
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #707070;
  font-size: 26px;
}

.form-input:focus{
  border-bottom: 2px solid black;
}

.t-n-c {
  font-size: 14px;
  color: #666666;
  padding-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.form-button-container {
  margin-top: 43px;
  max-width: 400px;
  margin:auto;
  margin-top:70px;
  display: flex;
  justify-content: space-between;
}
.form-extras{
  margin-top: 8px;
  font-size: 14px;
  color:#707070;
}

.form-back-btn {
  width: 188px;
  height: 72px;
  font-size: 18px;
  font-weight: 600;
  padding: 20px 18px;
  border: solid 2px #000000;
  background-color: #ffffff;
  /* margin: 0 15px; */
}

.form-proceed-btn {
  width: 188px;
  height: 72px;
  font-size: 18px;
  font-weight: 600;
  /* margin: 0 15px; */
  color: white;
  padding: 20px 18px;
  border: solid 2px #000000;
  background-color: #000000;
}

.payment-btn {
  width: 100%;
}

.form-btn-icon {
  position: relative;
  top: -2px;
  font-size: 22px;
}

.form-resend-otp {
  margin-left: auto;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 18px;
  font-weight: 600;
  color: #ed3833;
  padding-top: 8px;
  cursor: pointer;
}

.select-number-type-icon {
  height: 24px;
  width: 24px;
}


.number-card-two {
  width: 343px;
  color: white;
  height: 64px;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: #196cca;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
  margin: auto;
}

.number-card-two:hover {
  background-color: #1259A8;
}

.more-card {
  width: 343px;
  color: #196cca;
  fill: #196cca;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  height: 64px;
  box-sizing: border-box;
  padding: 12px 16px;
  background-color: white;
  align-items: center;
  display: flex;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  margin: auto;
  /* mix-blend-mode: normal; */
}

.more-card:hover {
  color: #1259A8;
  fill: #1259A8;
  /* mix-blend-mode: normal; */
}

.number-card-section {
  text-align: center;
  margin-top: 80px;
}

.number-card-section-top{
  margin-top: 0;
}

.number-card-section h1 {
  font-size: 34px;
  font-weight: bold;
  margin: 0;
  margin-bottom: 16px;
}

.number-card-section h2 {
  font-size: 21px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 40px;
}

@media (max-width:1200px) {
  .number-card-container {
    max-width: 700px;
  }
}

@media (max-width: 600px) {
  .title {
margin:auto;
    font-size: 24px;
    width: 80%;
  }

  .form-otp-sent{
    font-size: 14px;
  }

  .sub-title {
    margin: 16px auto 0 auto;
    width: 80%;
    font-size: 14px;
  }

  .number-type-card-container {
    margin-top: 24px;
  }

  .number-type-card {
    width: 94%;
    grid-template-columns: none;
    grid-template-rows: auto auto;
    margin: 8px auto;
    padding: 16px;
  }

  .number-type-card h1 {
    font-size: 16px;
  }

  .number-type-card h2 {
    font-size: 14px;
    max-width: 100%;
  }

  .number-type-card-section-two {
    margin-top: 16px;
  }

  .number-type-card-section-two h2 {
    float: left;
  }

  .select-number-type-icon {
    width: 16px;
    height: 16px;
    position: relative;
    top: 16px;
    left: 8px;
  }

  .number-starts-from {
    font-size: 12px;
    text-align: left;
  }

  .number-card-container {
    margin-top: 24px;
    padding-left: 0;
    padding-right: 0;
    grid-template-columns: repeat(auto-fit, minmax(94%, 1fr));
  }

  .number-card {
    width: 94%;
    margin: auto;
    padding: 14px 16px;
  }

  .number-card h3 {
    font-size: 16px;
  }

  .number-card p {
    font-size: 12px;
  }

  .number-card h4 {
    font-size: 16px;
  }

  .number-card img {
    width: 16px;
    height: 16px;
  }

  .input-form {
    width: 90%;
  }

  .form-input {
    font-size: 21px;
  }

  .t-n-c {
    font-size: 14px;
    color: #666666;
    padding-top: 24px;
  }

  .form-button-container {
    margin-top: 40px;
    flex-direction: column-reverse;
  }
  .form-extras{
    font-size: 12px;
  }

  .form-back-btn {
    width: 100%;
    height: 54px;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 18px;
    margin: 4px auto;
    border-radius: 5px;
  }

  .form-proceed-btn {
    width: 100%;
    height: 54px;
    font-size: 16px;
    font-weight: 600;
    margin: 4px auto;
    border-radius: 5px;
    color: white;
    padding: 14px 18px;
  }

  .payment-btn {
    max-width: 340px;
  }

  .form-btn-icon {
    font-size: 16px;
  }

  .form-resend-otp {
    text-align: center;
    font-size: 14px;
    padding-top: 16px;
  }


  .number-card-section {
    text-align: left;
    width: 343px;
    margin: auto;
    margin-top: 54px;
  }

  .number-card-section-top{
    margin-top: 0;
  }

  .number-card-section h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 0;
    line-height: 1.13;
    margin-bottom: 4px;
  }

  .number-card-section h2 {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    line-height: 1.14;
    margin-bottom: 16px;
    color: #939598;
  }

}
.cp-layout {
  text-align: center;
}
.cp-layout h1 {
  font-size: 34px;
  font-weight: bold;
}
.cp-layout h6 {
  font-size: 18px;
}

.cp-info {
  /* margin-top: 74px; */
  margin-bottom: 34px;
}

.cp-card {
  max-width: 506px;
  border-radius: 6px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #d2d3d4;
  background-color: #fff;
  margin: 8px auto;
  display: grid;
  grid-template-columns: 70% 20% 10%;
  padding: 24px;
  cursor: pointer;
}

.cp-card h1 {
  font-size: 34px;
  font-weight: bold;
  text-align: left;
  margin: 0;
}
.cp-card h3 {
  font-size: 26px;
  font-weight: 500;
  text-align: left;
  color: #939598;
  margin: 0;
}
.cp-card p {
  font-size: 18px;
  text-align: right;
  margin: 0;
}

.cp-card h4 {
  font-size: 34px;
  font-weight: 600;
  text-align: right;
  color: #000;
  margin: 0;
}

.cp-card img {
  width: 24px;
  height: 24px;
}

.cp-learn-more {
  font-size: 18px;
  font-weight: 500;
}
.cp-learn-more span {
  color: #196cca;
  text-decoration: underline;
}

.cp-ekyc-message {
  margin: 18px auto 30px;
  max-width: 394px;
  font-size: 18px;
  font-weight: 500;
}

@media (max-width: 600px) {
  .cp-layout h1 {
    max-width: 340px;
    font-size: 24px;
    margin:auto;
  }
  .cp-layout h6 {
    font-size: 14px;
  }

  .cp-info {
    margin-bottom: 24px;
  }

  .cp-card {
    max-width: 340px;
    border: solid 1px #d2d3d4;
    background-color: #fff;
    margin: 8px auto;
    display: grid;
    grid-template-columns: 70% 20% 10%;
    padding: 16px;
  }

  .cp-card h1 {
    font-size: 24px;
  }
  .cp-card h3 {
    font-size: 20px;
  }
  .cp-card p {
    font-size: 14px;
  }
  .cp-card h4 {
    font-size: 24px;
  }
  .cp-card img {
    width: 16px;
    height: 16px;
  }
  .cp-learn-more {
    font-size: 16px;
    width: 220px;
    margin: auto;
  }
  .cp-ekyc-message {
    max-width: 340px;
    font-size: 16px;
  }
}


.auth-container {
  text-align: center;
}

.auth-container form {
  max-width: 450px;
  width: 90%;
  margin: auto;
  margin-top: 100px;
}

.auth-container h1 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 36px;
}

.auth-container h3 {
  font-size: 18px;
}

.auth-container input {
  margin-top: 20px;
  text-align: center;
  margin-bottom: 16px;
  border: none;
  border-bottom: 1px solid black;
  width: 100%;
  max-width: 500px;
  font-size: 22px;
}

.auth-container div {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: auto;
  /* margin-top: 10px; */
  /* margin-bottom: 20px; */
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
}

.auth-container button {
  background-color: black;
  border: none;
  color: white;
  width: 100%;
  max-width: 500px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
  padding: 16px;
  margin-top: 36px;
}

.auth-red {
  color: #ed3833;
  text-decoration: underline;
  -webkit-text-decoration-color: red;
          text-decoration-color: red;
}

.auth-blue {
  color: #196cca;
  text-decoration: underline;
  -webkit-text-decoration-color: blue;
          text-decoration-color: blue;
}

.new-account{
  margin-top: 24px;
  font-size: 18px;
  font-weight: 600;
  cursor:pointer;
}

@media (max-width: 600px) {
  .new-account{
    font-size: 16px;
  }
}

.success-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.success-page h1{
  font-size: 34px;
  font-weight: bold;
  color: #196cca;
  margin:0;
  margin-bottom: 10px;
}

.success-page h2{
  font-size: 30px;
  font-weight: 500;
  margin:0;
  margin-bottom: 40px;
}

.success-page-container{
  box-shadow: -1px 0 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #f6f6f6;
  width: 656px;
  height: 642px;
  align-items: center;
  margin: 0 0 54.8px;
  padding: 80px 125.6px 80px 126px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.success-page-container h1{
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 45px;
}

.success-page-container div{
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  grid-gap:24px;
  gap:24px;
}

.success-page-container p{
  margin: 0;
  font-size: 26px;
  font-weight: normal;
  line-height: 1.38;
}

.store-container{
  margin-top:50px;
  width:400px;
}

.to-account{
  width: 320px;
  height: 54px;
  font-size: 18px;
  font-weight: 600;
  /* margin: 0 15px; */
  color: white;
  padding: 12px 18px;
  border: solid 2px #000000;
  background-color: #000000;
  margin-bottom: 50px;
}



@media screen and (max-width: 600px) {
  .success-page{
  }
  
  .success-page h1{
    font-size: 26px;
    margin-bottom: 4px;
  }
  
  .success-page h2{
    font-size: 18px;
    margin-bottom: 24px;
  }
  
  .success-page-container{
    box-shadow: none;
    width: 100%;
    height: 394px;
    margin: 0 0 54.8px;
    padding: 40px 0 40px 0;
  }
  
  .success-page-container h1{
    font-size: 20px;
    margin-bottom: 24px;
  }
  
  .success-page-container div{
   width: 64%;
   margin-bottom: 22px;
   grid-gap:16px;
   gap:16px;
  }
  
  .success-page-container p{
    margin: 0;
    font-size: 18px;
  }
  
  .store-container{
    margin-top: 20px;
  }
  
  .to-account{
    width: 320px;
    height: 54px;
    font-size: 18px;
    font-weight: 600;
    /* margin: 0 15px; */
    color: white;
    padding: 12px 18px;
    border: solid 2px #000000;
    background-color: #000000;
  }

  .PlayStore{
    width: 149px;
    height: 44px;
  }
  .AppStore{
    width: 124px;
    height: 44px;
  }
}
.GiftPageHead {
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.GiftPageSubHead {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.PageOneHeadSpacing {
  margin-top: 150px;
  margin-bottom: 0;
}

.SubHeadPlanSpacing {
  margin-bottom: 44px;
}

.NoMargin {
  margin: 0;
}

.GiftCardHolder {
  display: grid;
}

.CounterContainer {
  grid-area: counter;
  margin: auto 0;
}

.PlusMinus {
  background: black;
  color: white;
  border: 1px solid black;
  padding: 10px;
}

.PlusMinusIcon {
  width: 14px;
}

.Counter {
  border: 1px solid black;
  padding: 10px;
}

.PlanAmount {
  text-align: right;
  font-size: 24px;
}

.SecondBox {
  margin: auto 0;
  grid-area: amount;
  text-align: left;
}

.PlanDetails {
  grid-area: pd;
  margin: auto 0;
}

.PlanName {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.FreeNumberIncluded {
  font-family: Montserrat;
  font-size: 14px;
  color: #666666;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.CheckMark {
  width: 14px;
}

.Ribbon {
  background: linear-gradient(to bottom, #c1c1c1, #8b8b8b);
  width: 18px;
  grid-area: ribbon;
}

.Yellow {
  background: linear-gradient(to bottom, #fcde9b, #ffb100);
}

.BestValue {
  /* height: 11px; */
  float: right;
  text-align: left;
  font-family: Montserrat;
  font-size: 9px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  background-color: #d31818;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1px 10px;
}

.GiftPlanHolder {
  margin: 16px auto;
  border: solid 1px #e3e3e3;
  max-width: 784px;
  height: 96px;
  display: grid;
  grid-template-columns: 30px 300px 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "ribbon pd amount counter";
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
}

.Total {
  grid-area: t;
  margin: auto 36px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.TotalAmount {
  grid-area: ta;
  margin: auto 36px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: right;
}

.AmountText {
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  /* text-align: center; */
}

.InputPadding {
  margin-top: 60px;
}

.TotalContainer {
  margin: 0 auto;
  border: solid 1px #000000;
  max-width: 784px;
  height: 96px;
  display: grid;
  grid-template-columns: "auto auto auto";
  grid-template-areas: "t . ta";
}

.ButtonSpacingPageOne {
  margin-top: 74px;
  margin-bottom: 39px;
}

.ButtonSpacingPageTwo {
  margin-top: 34px;
  margin-bottom: 39px;
}

.GiftCouponBuyButton {
  background-color: #000000;
  color: white;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  border: none;
  width: 235.6px;
  height: 72px;
}

.DisabledButton {
  background-color: #d5d5d5;
}

.SuccessCheckMark {
  width: 92px;
  margin: 20px;
}

.DoNextHeader {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  margin-top: 80px;
  margin-bottom: 40px;
}

.ContainerOne {
  grid-area: one;
}

.ContainerTwo {
  grid-area: two;
}

.ContainerImage {
  float: left;
}

.ContainerText {
  float: right;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  width: 75%;
}

.DoNextContainer {
  display: grid;
  grid-template-columns: 30% auto 5% auto 25%;
  grid-template-areas: ". one . two .";
}

.gift-card-discount-container {
  display: flex;
  max-width: 784px;
  margin: 40px auto 10px auto;
  grid-gap: 16px;
  gap: 16px;
}

.gift-card-discount-input-container {
  color: #939598;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  /* border: none; */
  width: 622px;
  padding: 16px;
  border: solid 1px #e3e3e3;
}

.gift-card-discount-input-container input {
  font-size: 16px;
  font-weight: 500;
  width: 90%;
  border: none;
}

.gift-card-discount-input-container img {
  width: 24px;
  height: 24px;
}

.gift-card-discount-container button {
  background-color: #d5d5d5;
  color: #ffffff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  border: none;
  border-radius: 4px;
  font-size: 16px;
  width: 144px;
  font-weight: 600;
}

.gift-card-discount-message-container {
  margin-bottom: 24px;
}

.gift-card-tnc {
  margin-bottom: 30px;
}

.gift-card-tnc p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #929598;
}

@media screen and (max-width: 600px) {
  .SecondBox {
    width: 120px;
  }
  .PlanAmount {
    float: left;
  }
  .BestValue {
    float: right;
    margin-top: 4px;
    font-size: 10px;
    padding-left: 4px;
    padding-right: 4px;
  }
  .PlanName {
    line-height: 1;
  }
  .GiftPlanHolder {
    /* margin-left: 10px; */
    /* margin-right: 10px; */
    width: 90%;
    grid-template-columns: 30px auto auto auto;
    grid-template-areas: "ribbon amount . counter " "ribbon pd . counter";
  }
  .TotalContainer {
    width: 90%;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
  }
  .GiftCouponBuyButton {
    width: 90%;
    height: 80px;
    font-size: 20px;
  }
  .ButtonSpacingPageOne {
    margin-top: 110px;
    margin-bottom: 24px;
  }
  .ButtonSpacingPageTwo {
    margin-top: 34px;
    margin-bottom: 39px;
  }
  .DoNextContainer {
    grid-template-columns: 5% auto 10%;
    grid-template-rows: auto auto;
    grid-template-areas: ". one ." ". two .";
  }
  .PageOneHeadSpacing {
    margin-top: 0;
  }
  .GiftPageHead {
    font-size: 26px;
    max-width: 340px;
    margin: auto;
  }
  .GiftPageSubHead {
    font-size: 18px;
    max-width: 340px;
    margin: 10px auto 30px auto;
  }
  .GiftCouponBuyButton {
    height: 50px;
  }
  .InputPadding {
    margin-top: 110px;
  }
  .PlanDetails {
    margin: 0;
  }
  .PlanAmount {
    font-size: 18px;
  }
  .PlanName {
    font-size: 18px;
  }
  .FreeNumberIncluded {
    font-size: 10px;
  }
  .Total {
    margin: auto 20px;
  }
  .TotalAmount {
    margin: auto 20px;
  }
  .DoNextHeader {
    font-size: 24px;
    margin-top: 60px;
    margin-bottom: 20px;
  }
  .ContainerText {
    font-size: 22px;
  }
  .PlusMinus {
    padding: 6px;
  }
  .Counter {
    padding: 6px;
  }
  .gift-card-discount-container {
    flex-direction: column;
    width: 90%;
    /* display: flex; */
    /* max-width: 784px; */
    /* margin: 40px auto 10px auto; */
    /* gap: 16px; */
  }

  .gift-card-discount-input-container {
    width: 100%;
  }

  .gift-card-discount-input-container input {
    font-size: 16px;
    font-weight: 500;
    width: 90%;
    border: none;
  }

  .gift-card-discount-input-container img {
    width: 24px;
    height: 24px;
  }

  .gift-card-discount-container button {
    width: 100%;
    padding: 12px;
  }

  .gift-card-discount-message-container {
    margin-bottom: 24px;
  }
}

/* #33a9bb */
.Error {
  color: red;
  min-height: 24px;
  margin: 0;
  max-width: 400px;
  font-size: 14px;
  margin: auto;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.Success {
  /* color: red; */
  height: 24px;
  margin: 0;
}

.HoverDoosraEffect {
  cursor: pointer;
  color: #ed3833;
}

.PlainHover {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.Button:active,
.Button:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.Red {
  color: red;
}

.Green {
  color: #53b416;
}

.Grey {
  opacity: 0.5;
}

.LeftBox {
  float: right;
}

.MiddleBox {
  margin-left: auto;
  margin-right: auto;
}

.RightBox {
  float: left;
}

.Stepsbar {
  border-bottom: 6px solid black;
  padding-bottom: 22px;
  margin: 0 auto;
  width: 100%;
}

.Step {
  margin-left: 2vw;
  margin-right: 2vw;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.CurrentStep {
  /* color: red; */
  font-size: 1.1vw;
  border-bottom: 6px solid #ed3833;
  margin-left: 2vw;
  margin-right: 2vw;
  padding-bottom: 20px;
  /* font-family: Montserrat-SemiBold; */
  font-family: Montserrat;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

.PageHeading {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.24; */
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-bottom: 2vw;
}

.Inputs {
  background: transparent;
  border: none;
  /* margin-top: 25px; */
  margin-top: 1.4vh;
  /* padding-top: 25px; */
  padding-bottom: 10px;
  font-size: 26px;
  font-size: 1.6vw;
  border-bottom: 3px solid black;
  outline: none;
  text-align: center;
  /* width: 20%; */
  min-width: 25vw;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.InputTwo {
  margin: 1.4vh 0;
  padding-bottom: 10px;
  font-size: 26px;
  min-width: 25vw;
  background: transparent;
  border: none;
  border-bottom: 3px solid black;
  outline: none;
  text-align: center;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.PaymentButton {
  padding: 10px 70px;
  cursor: pointer;
  margin-top: 20px;
  border: 0;
  background-color: #ed3833;
  border: none;
  margin-bottom: "50px";
}

.PaymentButton:active,
.PaymentButton:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.DiscountApplyButton {
  background-color: black;
  color: white;
  padding: 18px 22px;
  cursor: pointer;
  margin-top: 2vw;
  border: 0;
}

.Button {
  background-color: black;
  color: white;
  padding: 1vw 1vw;
  cursor: pointer;
  margin-top: 2vw;
  border: 0;
}

.invert {
  background-color: white;
  color: black;
  border: none;
}

.ButtonText {
  font-family: Montserrat;
  font-size: 0.8vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.ButtonTextTwo {
  font-family: Montserrat;
  font-size: 1.2vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.ButtonTypeOne {
  /* width: 180px; */
  width: 10vw;
  margin-top: 0;
}

.ButtonTypeTwo {
  width: 18vw;
}

.ForwardArrowPosition {
  /* margin-left: 50px; */
  margin-left: 3vw;
}

.OptionsInfoContainer {
  width: 14vw;
}

.OptionsInfoContainer h1 {
  cursor: pointer;
  font-family: Montserrat;
  /* font-size: 34px; */
  font-size: 1.6vw;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.NumberSelectionBorder {
  cursor: pointer;
}

.OptionsInfoContainer h1:hover {
  color: #ed3833;
}

.Disable h1:hover {
  color: #999999;
}

.OptionsInfoContainer h3 {
  height: 22px;
  font-family: Montserrat;
  /* font-size: 18px; */
  font-size: 0.9vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  /* margin-bottom: 35px; */
  margin-bottom: 1.5vw;
}

.DoneOptions {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 25px;
}

.StartsFrom {
  /* width: 50px; */
  width: 2.6vw;
  font-family: Montserrat;
  /* font-size: 18px; */
  font-size: 0.95vw;
  /* margin-right: 10px; */
  margin-right: 0.5vw;
  position: relative;
  top: -3px;
  line-height: 1.11;
}

.INR {
  /* width: 44px; */
  /* height: 29px; */
  height: 1.5vw;
  font-family: Montserrat;
  /* font-size: 24px; */
  font-size: 1.4vw;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  /* margin-right: 10px; */
}

.INR2 {
  font-family: Montserrat;
  font-size: 18px;
  margin-right: 3px;
}

.INRAmount {
  width: 104px;
  width: 6vw;
  height: 66px;
  font-family: Montserrat;
  font-size: 54px;
  font-size: 2.4vw;
  font-weight: 600;
  line-height: 0.67;
  text-align: center;
}

.INRAmount2 {
  font-family: Montserrat;
  font-size: 24px;
}

.DisplayDoosraNumbersTable {
  margin: 0 auto;
}

/* .DisplayDoosraNumbersTable th {
  padding: 0 1vw;
} */

.DisplayDoosraNumbers {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: 600;
  line-height: 1.06;
  color: #ed3833;
  text-align: left;
  /* padding-right: 1vw */
}

.DisplayDoosraNumber {
  font-family: Montserrat;
  /* font-size: 34px; */
  font-size: 2.4vw;
  font-weight: 600;
  line-height: 1.06;
  color: #ed3833;
  cursor: pointer;
  text-align: center;
  padding-right: 1vw;
}

.DoosraNumber {
  font-family: Montserrat;
  /* font-size: 55px; */
  font-size: 3.5vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: center;
  /* color: #ed3833; */
  cursor: pointer;
}

.DisplayDoosraNumbersTableNoColumn {
  /* min-width: 240px; */
  margin: 8px;
  display: block;
  /* background-color: none; */
  /* border: none; */
}

.DisplayDoosraNumbersTablePriceColumn {
  text-align: left;
  min-width: 130px;
  padding: 0;
}

.HeaderOne {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.24;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.HeaderTwo {
  font-family: Montserrat;
  font-size: 26px;
  font-size: 1.52vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.GreyHeaderOne {
  font-family: Montserrat;
  font-size: 16px;
  text-align: center;
  color: #666666;
}

.DropSelectedNumber {
  font-family: Montserrat;
  font-size: 16px;
  cursor: pointer;
  color: #666666;
}

.DropSelectedNumber:hover,
.DropSelectedNumber:active,
.DropSelectedNumber:visited,
.DropSelectedNumber:focus {
  text-decoration: none;
  color: #ed3833;
}

.ResendOTPHolder {
  margin: 0 auto;
  /* max-width: 500px; */
  max-width: 25vw;
  text-align: right;
}

.ResendOTP {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #196cca;
  cursor: pointer;
  /* position: relative; */
  /* left: 9%; */
  /* font-size: 18px; */
  font-size: 1vw;
}

.ForgotPassword {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  cursor: pointer;
  font-size: 18px;
}

.NumberNotAllottedHeading {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: bold;
  line-height: 2.24;
  text-align: center;
  color: #000000;
}

.NumberNotAllottedSubHeading {
  font-family: Montserrat;
  font-size: 1.52vw;
  line-height: 1.69;
  color: #000000;
  margin: 0 18%;
}

.NumberNotAllottedImage {
  margin-top: 20px;
  width: 20vw;
}

.DoneOptionContainer {
  display: inline-flex;
  height: 120px;
  width: 370px;
}

.DoneTick {
  float: left;
  margin-top: 10px;
}

.DoneContent {
  float: right;
  padding: 10px;
}

.AlignContentVertically {
  display: inline;
}

.TermsCheckBoxContainer {
  width: 25vw;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.termsnConditions {
  font-size: 1vw;
}

.TermsLink {
  color: #ed3833;
  cursor: pointer;
  /* font-size: 16px; */
  font-size: 1vw;
}

.DiscountCodeContainer {
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* max-width: 500px; */
  height: 50px;
  display: grid;
  margin-bottom: 30px;
  grid-template-columns: auto auto;
}

.DiscountCodeBox {
  border: 1.5px dashed grey;
  padding: 12px 15px;
  /* padding: 1.2vw 1.25vw; */
  margin-right: 10px;
}

.discount-code-box {
  border: 1.5px dashed grey;
  padding: 12px 15px;
  margin-right: 10px;
}

.discount-apply-btn {
  padding: 15px 25px;
  background-color: #000000;
  color: white;
}

.DiscountCodeInput {
  border: none;
  /* font-size: 24px; */
  font-size: 1.3vw;
  width: 250px;
}

.DiscountCodeContainerPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
}

.DiscountCodeBoxPage {
  border: 1.5px dashed grey;
  /* padding: 23px 25px; */
  padding: 22px;
  margin-right: 10px;
}

.DiscountCodeInputPage {
  border: none;
  /* font-size: 24px; */
  font-size: 1.3vw;
  max-width: 240px;
}

input:focus {
  outline-width: 0;
}

.DiscountCodeCancel {
  width: 20%;
}

.OptionsContainer {
  display: flex;
  flex-direction: row;
}

.OptionHolder {
  width: 33%;
}

.HeaderBottomPadding {
  padding-bottom: 4vw;
}

.CenterContainer {
  text-align: center;
  text-align: -webkit-center;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
}

.CenterContainerForgotPassword {
  text-align: center;
  text-align: -webkit-center;
  margin-top: 6%;
  margin-bottom: 4%;
  margin-left: auto;
  margin-right: auto;
}

.WaitlistHeading {
  width: 596px;
  height: 42px;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
}

.TimerHolder {
  width: 14vw;
  /* margin: 2ch; */
  margin: 1vw auto;
  background-color: #f4f4f4;
  padding: 1vh;
}

.TimerText {
  font-family: Montserrat;
  font-size: 1.4vw;
  margin: 5px;
  color: #666666;
}

.TimerTime {
  /* float: right;
  width: 2vw; */
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.5vw;
  margin: 5px;
  color: black;
  display: inline-block;
  width: 85px;
}

.DiscountCancelButton {
  max-width: 25px;
  position: relative;
  top: -2px;
  cursor: pointer;
}



.playStoreHolder {
  margin-top: 5vw;
}


.PlayStoreSpacing {
  padding-left: 40px;
}

.SoldOut {
  color: #666666;
  font-size: 25px;
}

.Disable {
  color: #999999;
  cursor: default;
}

.Disable h1 {
  cursor: default;
}

.NotFound {
  font-size: 10vw;
  color: #ed3833;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.NotFoundText {
  font-size: 3vw;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.RedirectLink {
  font-size: 1vw;
  color: #ed3833;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: underline;
}

.Tick {
  fill: #ed3833;
}

.UpgradeFromFree {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: bold;
  font-stretch: normal;
  font-style: bold;
  letter-spacing: normal;
  /* text-decoration: underline; */
  color: black;
}

.FreeTrialPaymentNote {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.ChangeNumber {
  color: #196cca;
  cursor: pointer;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.PasswordCheckContainer {
  background-color: #f2f2f2;
  width: 400px;
  min-width: 25vw;
  margin: 20px auto;
  padding: 20px;
}

.PasswordCheckContainer h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: bold;
  letter-spacing: normal;
  text-align: left;
}

.PasswordCheckContentContainer {
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: auto auto auto auto;
}

.PasswordCheckContentContainer p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.PasswordCheckContentContainer img {
  width: 18px;
}

.YouWillGetContainer {
  width: 420px;
  margin: 5px auto;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.3px #d1d3d4;
  background-image: linear-gradient(to bottom, #fcfcfc, #fcfcfc);
  position: relative;
}

.YouWillGetContainer h1 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 20px 0 20px 20px;
  margin: 0;
}

.YouWillGetContainer h2 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  padding-top: 20px;
  margin: 0;
}

.YouWillGetContainer h3 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.YouWillGetContainer img {
  width: 30px;
  position: absolute;
  top: 23px;
  left: -15px;
}

.upgradeforTenPrecentDiscount {
  color: #53b416;
  font-size: 16px;
  text-decoration: underline;
  -webkit-text-decoration-color: #53b416;
          text-decoration-color: #53b416;
}

.UpgradeTo12month {
  color: #ed3833;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}

.YouWillGetContainerOne img {
  top: 35px;
}

.ProratedMessage {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}

.RegisterPageOneOkButton {
  margin: 20px;
}

.OTPResent {
  display: flex;
  justify-content: center;
}
.OTPResent img {
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.OTPResent p {
  color: green;
  margin: 0;
  font-size: 14px;
}

@media screen and (max-width: 1600px) {
  .Step {
    margin-left: 25px;
    margin-right: 25px;
  }
  .CurrentStep {
    margin-left: 20px;
    margin-right: 20px;
  }
  .TimerTime {
    width: 50px;
    font-size: 18px;
  }
  .DisplayDoosraNumber {
    font-size: 34px;
  }
}

@media screen and (max-width: 1300px) {
  .CurrentStep {
    font-size: 18px;
  }
  .playStoreHolder {
    margin-top: 5vw;
  }
  .PlayStoreSpacing {
    padding-left: 0;
  }
  .Stepsbar {
    width: 100%;
  }
  .Step {
    display: none;
  }
  .PageHeading {
    font-size: 26px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5;
  }
  .HeaderOne {
    font-size: 26px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5;
  }
  .HeaderTwo {
    font-size: 18px;
    /* padding-left: 10%; */
    /* padding-right: 10%; */
    line-height: 1.5;
  }
  .DoosraNumber {
    font-size: 48px;
  }
  .Inputs {
    font-size: 20px;
  }
  .TimerHolder {
    width: 150px;
    margin: 5px auto;
    padding: 5px;
  }
  .TimerText {
    font-size: 18px;
  }
  .TimerTime {
    width: 50px;
    font-size: 18px;
  }
  .NumberNotAllottedHeading {
    font-size: 3vw;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5;
  }
  .NumberNotAllottedSubHeading {
    font-size: 2vw;
    /* padding-left: 10%; */
    /* padding-right: 10%; */
    line-height: 1.5;
    margin: 0 10%;
  }
  .NumberNotAllottedImage {
    margin-top: 20px;
    width: 40vw;
  }
  .DisplayDoosraNumbers {
    font-size: 3vw;
  }
  .NotFound {
    font-size: 20vw;
  }
  .NotFoundText {
    font-size: 5vw;
  }
  .RedirectLink {
    font-size: 2vw;
  }
  .ResetPasswordHolder {
    max-width: 333px;
  }
  .ButtonTextTwo {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .ResendOTPHolder {
    max-width: 265px;
  }
  .ResetPasswordHolder {
    max-width: 333px;
  }
  .ResendOTP {
    font-size: 14px;
  }
  .ButtonTypeOne {
    width: 90px;
  }
  .ButtonText {
    font-family: Montserrat;
    font-size: 1.4vw;
  }
  .ButtonTypeTwo {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2%;
  }
  .invert {
    width: 124px;
  }
  .TermsCheckBoxContainer {
    width: 265px;
  }
  .termsnConditions {
    font-size: 14px;
  }
  .TermsLink {
    font-size: 14px;
  }
  .OptionsContainer {
    flex-direction: column;
  }
  .OptionHolder {
    width: 100%;
  }
  .LeftBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .MiddleBox {
    margin-left: auto;
    margin-right: auto;
  }
  .RightBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 900px) {
  .OptionsInfoContainer {
    width: 280px;
  }
  .OptionsInfoContainer h1 {
    font-size: 24px;
  }
  .OptionsInfoContainer h3 {
    height: 16px;
  }
  .NumberSelectionBorder {
    /* border: 1px solid black; */
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.55);
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px 5px;
    cursor: pointer;
  }
  .LeftBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .MiddleBox {
    margin-left: auto;
    margin-right: auto;
  }
  .RightBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .StartsFrom {
    font-size: 14px;
    top: -3px;
    margin-right: 25px;
  }
  .INR {
    /* width: 20px; */
    height: 29px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }
  .INRAmount {
    width: 104px;
    height: 40px;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
  }
}

@media screen and (max-width: 1000px) {
.Stepsbar {
  margin-top: 36px;
}
}

@media screen and (max-width: 600px) {
  .CenterContainer {
    margin-top: 45%;
    width: 80%;
  }
  .CenterContainerForgotPassword {
    margin-top: 45%;
    width: 80%;
  }
  .Stepsbar {
    width: 100%;
  }
  .Step {
    display: none;
  }
  .Inputs {
    min-width: 0;
    width: 90%;
  }
  .TermsCheckBoxContainer {
    width: 100%;
  }
  .ResendOTPHolder {
    max-width: 90%;
  }
  .ResetPasswordHolder {
    max-width: 100%;
  }
  .ResendOTP {
    left: 30%;
  }
  .invert {
    margin: 0;
  }
  .WaitlistHeading {
    font-size: 24px;
    height: 70px;
    width: 280px;
  }
  .OptionsInfoContainer {
    width: 280px;
  }
  .OptionsInfoContainer h1 {
    font-size: 24px;
  }
  .OptionsInfoContainer h3 {
    height: 16px;
  }
  .MobileNextLine {
    /* white-space: nowrap; */
    display: inline-block;
    width: 100%;
  }
  /* .AlignContentVertically {
    display: inline-flex;
    flex-direction: column;
  } */
  .NumberSelectionBorder {
    /* border: 1px solid black; */
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.55);
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px 5px;
    cursor: pointer;
  }
  .DoneOptions {
    font-size: 18px;
  }
  .Tick {
    width: 40px;
    fill: #ed3833;
  }
  .TermsCheckBoxContainer {
    white-space: nowrap;
    padding-left: 5%;
  }
  .DoosraNumber {
    font-size: 36px;
  }
  .DiscountApplyButton {
    padding: 15px 25px;
    width: 30%;
    margin: 20px;
  }
  .Button {
    padding: 15px 25px;
    width: 30%;
    margin: 20px;
  }
  .ButtonTypeOne {
    width: 180px;
  }
  .ButtonTypeTwo {
    width: 295px;
    margin: 0 auto;
  }
  .ForwardArrowPosition {
    margin-left: 50px;
    /* margin-left: 3vw; */
  }
  .ForwardArrowPositionSelectNumber {
    margin-left: 30px;
  }
  .ButtonText {
    font-size: 14px;
  }
  .OptionsContainer {
    flex-direction: column;
  }
  .OptionHolder {
    width: 100%;
  }
  .DoneOptionContainer {
    width: 100%;
    height: 80px;
    padding: 4%;
  }
  .LeftBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .MiddleBox {
    margin-left: auto;
    margin-right: auto;
  }
  .RightBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .StartsFrom {
    font-size: 14px;
    top: -3px;
    margin-right: 35px;
  }
  .INR {
    /* width: 20px; */
    height: 29px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }
  .INRAmount {
    width: 104px;
    height: 40px;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
  }
  .HeaderBottomPadding {
    /* padding-bottom: 20px; */
    padding-bottom: 1.2vw;
  }
  .InputTwo {
    min-width: 0;
    width: 100%;
    font-size: 18px;
  }
  .DiscountCodeContainer {
    padding-top: 10px;
    margin-bottom: 10px;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }
  .DiscountCodeBox {
    margin: 20px;
    padding: 10px 15px;
    margin-top: 50px;
    max-width: 400px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .discount-code-box {
    border: 1.5px dashed grey;
    padding: 12px 15px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .discount-apply-btn {
    padding: 15px 25px;
  }

  .DiscountCodeInput {
    font-size: 18px;
    width: 200px;
  }
  .DiscountCodeContainerPage {
    padding-top: 25px;
  }
  .DiscountCodeBoxPage {
    margin: 20px;
    padding: 10px 15px;
    margin-top: 50px;
    max-width: 400px;
    margin-left: 10%;
    margin-right: 10%;
  }
  .DiscountCodeInputPage {
    max-width: 200px;
    font-size: 18px;
  }
  .DiscountCancelButton {
    max-width: 25px;
    position: relative;
    top: -1px;
  }
  .NumberNotAllottedHeading {
    font-size: 26px;
  }
  .NumberNotAllottedSubHeading {
    font-size: 18px;
    margin: 0 5%;
  }
  .NumberNotAllottedImage {
    margin: 20px;
    width: 60%;
  }
  .DisplayDoosraNumbersTableNoColumn {
    min-width: 190px;
    margin: 5px;
    display: block;
    background-color: none;
    border: none;
  }
  .DisplayDoosraNumbersTablePriceColumn {
    min-width: 130;
    padding: 0;
  }
  .DisplayDoosraNumbersTable tr {
    margin: none;
    border: none;
    background: none;
    display: revert;
  }
  .DisplayDoosraNumbers {
    font-size: 26px;
  }
  .NotFound {
    font-size: 40vw;
  }
  .NotFoundText {
    font-size: 10vw;
  }
  .RedirectLink {
    font-size: 4vw;
  }
  .PasswordCheckContainer {
    width: 90%;
    /* min-width: 25vw; */
    margin: 20px auto;
    padding: 20px;
  }
  .PasswordCheckContainer h1 {
    font-size: 16px;
  }
  .PasswordCheckContentContainer p {
    font-size: 14px;
  }
  .YouWillGetContainer {
    width: 300px;
  }
  .YouWillGetContainer h1 {
    font-size: 20px;
  }
  .YouWillGetContainer h2 {
    font-size: 20px;
  }
  .YouWillGetContainer h3 {
    font-size: 20px;
  }
  .YouWillGetContainer img {
    width: 30px;
    position: absolute;
    top: 20px;
    left: -15px;
  }
  .upgradeforTenPrecentDiscount {
    font-size: 14px;
  }
  .UpgradeTo12month {
    font-size: 14px;
  }
  .YouWillGetContainerOne img {
    top: 28px;
  }
  .DoneTick {
    float: left;
    margin-top: 0px;
  }
}

.maintainance-container {
  text-align: center;
  padding-top: 200px;
}

.maintainance-container img {
  max-width: 200px;
  margin-bottom: 41px;
}

.maintainance-container h2 {
  color: #ed3833;
  font-size: 32px;
  font-weight: bold;
}

.maintainance-container h3 {
  font-size: 18px;
  color: #00515a;
}

.secureDataContainer {
  height: 800px;
  background: black;
  text-align: center;
  padding-top: 100px;
}

.secureDataContainer h1 {
  font-size: 100px;
  font-weight: bold;
  color: #ffffff;
}

.secureDataContainer h2 {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
}

.secureDataContainer form {
  padding-top: 60px;
  padding-bottom: 24px;
  margin: auto;
}
.secureDataContainerInput {
  width: 469px;

  padding: 18px 66px;
  border: solid 2px #9f9f9f;

  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  text-align: center;
  color: #9f9f9f;
  /* color: #ffffff; */
  background-color: black;
}

.inputFound {
  color: #ffffff;
  border: solid 2px #ffffff;
}

/* .secureDataContainer input:focus {
  background-color: #ffffff;
  color: black;
} */

.secureDataContainer button {
  margin-left: 16px;
  width: 279px;
  height: 76px;
  background-color: #36aabb;
  border: none;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
}

.secureDataContainer button:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.secureDataContainer h5 {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #e3e3e3;
  max-width: 780px;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .secureDataContainer {
    height: 700px;
  }
  .secureDataContainer h1 {
    font-size: 45px;
  }
  .secureDataContainer h2 {
    font-size: 22px;
    width: 292px;
    margin: auto;
  }
  .secureDataContainer form {
    padding-top: 42px;
    padding-bottom: 18px;
  }
  .secureDataContainerInput {
    width: 328px;
    font-size: 18px;
    padding: 16px 38px;
  }
  .secureDataContainer button {
    width: 328px;
    font-size: 18px;
    padding: 16px 38px;
    margin-left: 0;
    margin-top: 16px;
    height: 56px;
  }
  .secureDataContainer h5 {
    font-size: 14px;
    width: 328px;
    margin: auto;
  }
}

.a_Doosra__Account-Info-1 {
  background-color: #ffffff;
  margin-left: 92px;
  margin-right: 92px;
  display: grid;
  grid-template-areas: "info-holder order-history";
  grid-template-columns: minmax(200px, 500px) auto;
  grid-gap: 24px;
  margin-bottom: 50px;
}

.account-info-card-holder {
  display: flex;
  flex-direction: column;
  grid-gap: 24px;
  gap: 24px;
  grid-area: info-holder;
}
.Toastify__toast--success {
  background: green;
}

.Account-Info {
  height: 42px;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  line-height: 2.24;
  margin-bottom: 50px;
  margin-left: 92px;
}

.side-box {
  background-color: #f1f1f1;
  padding: 43px 56px 33px 56px;
  display: flex;
  flex-direction: column;
}

.profile-box-element {
  justify-content: space-between;
}

.edit-img-icon {
  height: 1.125em;
  cursor: pointer;
}

.side-box-element {
  font-family: Montserrat;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.56; */
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  border-bottom: #ccc 1px solid;
  display: flex;
  /* justify-content: space-between; */
  /* border: red 1px solid; */
  /* height: 50px; */
  padding-bottom: 14px;
  margin-bottom: 24px;
}

.side-box h1 {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  border-bottom: black 2px solid;
  align-items: center;
}

.side-box-bold-element {
  font-weight: 600;
}

.doosra-number-box {
  /* grid-column: 1/2; */
  /* grid-row: 2/3; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.kyc-msg-box {
  /* grid-column: 1/2; */
  /* grid-row: 3/4; */
  background-color: #fcd8d7;
  display: flex;
  padding: 18px;
  flex-direction: row;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.kyc-msg-box p {
  margin: 0;
  padding-left: 18px;
  font-size: 16px;
  max-width: 415px;
}

.profile-box {
  /* grid-column: 1/2; */
  /* grid-row: 4 /5; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.side-box-sub-element {
  padding: 5px 0;
  font-size: 14px;
}

.invite-box {
  /* grid-column: 1/2; */
  /* grid-row: 5/6; */
}

.plan-box {
  /* grid-column: 1/2; */
  /* grid-row: 6/7; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.extra-box {
  /* grid-column: 1/2; */
  /* grid-row: 7/8; */
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.order-history-box {
  /* grid-column: 2/-1; */
  /* grid-row: 2 / none; */
  grid-area: order-history;
}

.order-table {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.89;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  grid-template-columns: 20% auto 20% 20%;
}
.order-table-header {
  border-bottom: black 2px solid !important;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.price-column {
  font-weight: 600;
  text-align: right;
  margin-right: 100px;
}

.order-box-element {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.pad-right {
  padding-right: 0.5em;
}

.discounted-payment {
  display: flex;
  flex-direction: column;
  line-height: 1.2;
  text-align: right;
  margin-bottom: 12px;
}
.discounted-payment h5 {
  margin: 0;
  line-height: 2;
  font-size: 18px;
}

.discounted-payment p {
  margin: 0;
  font-size: 12px;
  font-weight: normal;
}
.discounted-payment h6 {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
}

.account-info-card {
  background-color: #f1f1f1;
  padding: 43px 56px 33px 56px;
  display: flex;
  flex-direction: column;
}

.account-info-card h1 {
  font-size: 21px;
  font-weight: 600;
  color: #000000;
  align-items: center;
}

.account-info-card hr {
  border-bottom: 2px solid black;
}

.account-info-card h2 {
  font-size: 18px;
  cursor: pointer;
  font-weight: bold;
  color: #ee3048;
  padding-top: 40px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.account-info-card h5 {
  font-size: 16px;
  color: #555555;
}

@media only screen and (max-width: 1300px) {
  .Account-Info {
    grid-area: accInfo;
  }
  .doosra-number-box {
    grid-area: doosraNo;
  }
  .kyc-msg-box {
    grid-area: kycMsg;
  }
  .kyc-msg-box p {
    max-width: 100%;
  }
  .profile-box {
    grid-area: prof;
  }
  .invite-box {
    grid-area: invite;
  }
  .plan-box {
    grid-area: plan;
    padding: 43px 56px 33px 56px;
  }
  .order-history-box {
    grid-area: hist;
  }
  .extra-box {
    grid-area: extra;
  }
  .account-info-card-holder {
    grid-area: holder;
  }
  .a_Doosra__Account-Info-1 {
    display: grid;
    grid-template-columns: 10% auto 10%;
    grid-template-rows: auto;
    grid-gap: 24px;
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: 10px;
    box-sizing: border-box;
    grid-template-areas: ". holder ." ". hist . ";
  }

  .Account-Info {
    margin-left: 13%;
  }
}

@media only screen and (max-width: 600px) {
  .a_Doosra__Account-Info-1 {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto;
    grid-gap: 24px 0;
    margin: 0;
    padding: 0;
    width: 100vw;
    font-size: 10px;
    box-sizing: border-box;
    /* padding: 10px; */
  }

  .account-info-card-holder {
    width: 100vw;
  }

  .Account-Info {
    text-align: center;
    margin-left: 0;
  }

  .side-box {
    padding: 0;
    height: auto;
    padding: 10px;
    max-width: 100vw;
  }
  .Account-Info {
    /* padding: 15px; */
    margin-left: 0;
  }
  .order-box-element {
    padding: 10px;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 10px;
  }
  .doosra-number-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }
  .kyc-msg-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }
  .profile-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }

  .invite-box {
    grid-column: 1/-1;
    grid-row: auto;
    margin: 0 2%;
  }

  .plan-box {
    grid-column: 1/-1;
    grid-row: auto;
    padding: 10px;
    margin: 0 2%;
  }
  .extra-box {
    grid-column: 1/-1;
    grid-row: auto;
    padding: 10px;
    margin: 0 2%;
  }
  .account-info-card {
    padding: 10px;
    margin: 0 2%;
  }
  .order-history-box {
    grid-column: 1/-1;
    grid-row: auto;
    /* border: red 1px solid; */
  }
  table {
    border: 0;
  }
  table thead {
    display: none;
  }
  table tr {
    margin: 20px 10px;
    display: block;
    background-color: #fbfbfb;
    border: 1px solid #ddd;
    /* border: green 1px solid; */
  }
  table td {
    /* display: block;
        text-align: left; */
    font-size: 13px;
    border-bottom: 1px dotted #ccc;
    display: flex;
    flex-direction: column;
    padding: 0px 0.75em;
  }
  table td:last-child {
    border-bottom: 0;
  }
  table td:first-child {
    border-bottom: 0;
    font-weight: 600;
  }
  table td:before {
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: bold;
    margin-right: 20px;
    color: black;
  }
  .price-column {
    text-align: left;
  }
  .discounted-payment {
    text-align: left;
  }
}

.buy-minute-popup{
  width: 584px;
  height: 441px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0  16px;
  background-color: #fff;
  text-align: center;
}

.buy-minute-popup h1{
  font-size: 31px;
  font-weight: bold;
  margin-bottom: 8px;
}

.buy-minute-popup img{
  cursor: pointer;
}

.buy-minute-popup h2{
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 40px;
  color:#939598;
}

.buy-minute-popup h3{
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}

.buy-minute-popup h4{
  font-size: 22px;
  font-weight: 600;
}

.buy-minute-popup div{
  display: flex;
justify-content: space-around;
align-items: center;
width: 120px;
}

.buy-minute-popup-button{
  margin-top: 42px;
  border: none;
  display: flex;
  background-color: #000000;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  width: 448px;
  height: 72px;
  cursor: pointer;
}

.buy-minute-popup-button:disabled{
  background-color: #a8a8a8;
  cursor: default;
}

.close-minute-popup{
  width: 16px;
  height: 16px;
  align-self: flex-end;

}

.close-minute-popup-button{
display: none;
}

.buy-minute-success{
  margin-top: 48px;
  margin-bottom: 43px;
}


@media screen and (max-width: 600px) {

  .buy-minute-popup{
    width: 100%;
    height: 410px;
    padding: 40px 20px 10px  20px;
    border-radius: 18px 18px 0 0;
    
  }

  .close-minute-popup{
display: none;
  }

  .buy-minute-popup h1{
    font-size: 20px;
  }
  

  .buy-minute-popup h2{
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 40px;
  }
  
  .buy-minute-popup h3{
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  
  .buy-minute-popup h4{
    font-size: 22px;
    font-weight: 600;
  }
  
  .buy-minute-popup div{
    display: flex;
  justify-content: space-around;
  align-items: center;
  width: 120px;
  }
  
  .buy-minute-popup-button{
    width: 100%;
    height: 56px;
    font-size: 14px;
  }
  
  .buy-minute-popup-button:disabled{
    background-color: #a8a8a8;
    cursor: default;
  }
  

  .close-minute-popup-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    margin: 16px 0 0;
    font-size: 14px;
    border: solid 1px #d2d3d4;
    background-color: #fff;
  }

  .buy-minute-success{
    margin-bottom: 34px;
  }

}
.modal-content {
  border: black 12px solid !important;
  width: 550px;
  background-color: #ffffff;
  padding: 73px 69px;
}

.modal-content form {
  display: flex;
  flex-direction: column;
}

.modal-content h1 {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: 600;
  line-height: 1.33;
  text-align: left;
}

.modal-content input {
  height: 50px;
  width: 100%;
  font-family: Montserrat;
  font-size: 1.125em;
  line-height: 1.64;
  text-align: left;
  color: #666666;
  border: none;
  border-bottom: black 2px solid;
}

.modal-content input:focus {
  outline: none;
}

.modal-content .button-group {
  margin-top: 4em;
  /* margin-bottom:4em; */
}

.modal-content button {
  width: 138.4px;
  height: 42.8px;
  border: solid 3px #000000;
  background-color: #000000;
  font-size: 1.125em;
  color: #ffffff;
}

.modal-content .cancel-button {
  background-color: white;
  color: #858585;
  border: white 3px solid;
}

.new-number-otp-box {
  display: flex;
  justify-content: space-between;
  border-bottom: black 2px solid;
}

.new-number-otp-box button {
  align-self: center;
  height: 2.4em;
  width: 68px;
}

.new-number-otp-box input {
  border: none;
}

.user-Message {
  color: red;
  margin-top: 1em;
}

.cross-button {
  position: absolute;
  top: 2em;
  right: 2em;
  height: 1.3em;
  width: 1.3em;
}

.cancel-subscription-box {
  display: flex;
  flex-direction: column;

  padding-bottom: 16px;
  margin-bottom: 24px;
}

.modal-content p {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 1.4em;
}

.cancel-subscription-box span {
  /* margin-bottom: 1em; */
  font-size: 1.125em;
  /* line-height: 1.56; */
}

.cancel-subscription-box span:last-child {
  color: #ed3833;
  cursor: pointer;
}

#cancel-subscription-button-group {
  margin-top: 0em;
}

@media only screen and (max-width: 600px) {
  .modal {
    margin: 0;
    padding: 0;
  }
  .modal-dialog {
    margin: 0 !important;
    padding: 0;
  }
  .modal-content {
    padding: 35px 20px;
  }
  .modal-content .button-group button {
    width: 50%;
  }
}

.steps {
  margin-bottom: 10px;
  padding-right: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  text-align: center;
  color: #939598;
}
.delete-account-head {
  margin-bottom: 10px;
  padding-right: 10px;
  font-family: Montserrat;
  font-size: 31px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}
.delete-account-subhead {                 
  margin-bottom: 40px;
  padding-right: 10px;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #000;
}

.delete-account-info {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.text-style-1 {
  color: #ee3048;
  text-decoration: underline;
}

.text-style-1:hover {
  cursor: pointer;
}

.text-style-2 {
  font-weight: 600;
  color: #ee3048;
}

.text-style-3 {
  color: #196cca;
  text-decoration: underline;
}
.text-style-3:hover {
  cursor: pointer;
}

.Rectangle-9565 {
  width: auto;
  height: auto;
  padding: 16px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16);
  background-color: #f1f2f2;
}

.delete-account-footer {
  margin-top: 20px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

.accept-button {
  width: auto;
  height: 72px;
  margin-top: 28px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.4s;
}

.button-text {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  color: #fff;
}

.accept-button:hover {
  background-color: #ed3131;
  cursor: pointer;
}

.Back {
  margin-top: 28px;
  padding-right: 5px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.caution-action {
  margin-bottom: 30px;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #ee3048;
}

.otp-caution {
  margin: 9px 4px 40px 6px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #939598;
}

.delete-account-info-inlay {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: #000;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

