.secureDataContainer {
  height: 800px;
  background: black;
  text-align: center;
  padding-top: 100px;
}

.secureDataContainer h1 {
  font-size: 100px;
  font-weight: bold;
  color: #ffffff;
}

.secureDataContainer h2 {
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
}

.secureDataContainer form {
  padding-top: 60px;
  padding-bottom: 24px;
  margin: auto;
}
.secureDataContainerInput {
  width: 469px;

  padding: 18px 66px;
  border: solid 2px #9f9f9f;

  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;

  letter-spacing: normal;
  text-align: center;
  color: #9f9f9f;
  /* color: #ffffff; */
  background-color: black;
}

.inputFound {
  color: #ffffff;
  border: solid 2px #ffffff;
}

/* .secureDataContainer input:focus {
  background-color: #ffffff;
  color: black;
} */

.secureDataContainer button {
  margin-left: 16px;
  width: 279px;
  height: 76px;
  background-color: #36aabb;
  border: none;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  text-align: center;
  color: #ffffff;
}

.secureDataContainer button:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.secureDataContainer h5 {
  font-size: 22px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.27;
  letter-spacing: normal;
  text-align: center;
  color: #e3e3e3;
  max-width: 780px;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .secureDataContainer {
    height: 700px;
  }
  .secureDataContainer h1 {
    font-size: 45px;
  }
  .secureDataContainer h2 {
    font-size: 22px;
    width: 292px;
    margin: auto;
  }
  .secureDataContainer form {
    padding-top: 42px;
    padding-bottom: 18px;
  }
  .secureDataContainerInput {
    width: 328px;
    font-size: 18px;
    padding: 16px 38px;
  }
  .secureDataContainer button {
    width: 328px;
    font-size: 18px;
    padding: 16px 38px;
    margin-left: 0;
    margin-top: 16px;
    height: 56px;
  }
  .secureDataContainer h5 {
    font-size: 14px;
    width: 328px;
    margin: auto;
  }
}
