.mp-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 68px;
  margin-bottom: 30px;
}

.mp-user {
  font-size: 34px;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center;
}

.you-will-get-card {
  width: 420px;
  margin: 5px 4px;
  padding: 34px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%);
  border: 0.3px solid #d1d3d4;
  background-image: linear-gradient(180deg, #fcfcfc, #fcfcfc);
  position: relative;
  text-align: center;
}

.you-will-get-card h1 {
  margin: 0;
  font-size: 26px;
  font-weight: bold;
}

.you-will-get-card h2 {
  margin: 0;
  font-size: 34px;
  font-weight: 600;
}

.you-will-get-card p {
  margin: 0;
  font-size: 14px;
  font-weight: 600;
  color: #ed3833;
  text-decoration: underline;
}

.you-will-get-card img {
  width: 57px;
  height: 57px;
  position: absolute;
  left: -30px;
  top: 24%;
}

.code-box-holder {
  margin-top: 32px;
}

.code-box-container {
  width: 100%;
  display: flex;
  margin: 4px auto;
  max-width: 440px;
}
.code-input-container {
  border-radius: 4px;
  border: dashed 1px #707070;
  display: flex;
  align-items: center;
  width: 100%;
}
.code-input-container input {
  border: none;
  margin: 8px;
  width: 100%;
  font-size: 16px;
  color: #939598;
}

.code-input-container img {
  height: 22px;
  margin: 8px;
}
.apply-code {
  width: 120px;
  height: 56px;
  padding: 16px 32px;
  border-radius: 4px;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #939598;
  margin-left: 12px;
}

.apply-code-active {
  background-color: black;
}

.timer-container {
  width: 200px;
  padding: 22px;
  background-color: #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.timer-container p {
  font-size: 26px;
  color: #666;
  margin: 0;
  margin-right: 8px;
}
.timer-container h2 {
  font-size: 26px;
  font-weight: bold;
  margin: 0;
}

@media (max-width: 600px) {
  .mp-container {
    flex-direction: column;
    justify-content: space-around;
    margin-top: 20px;
    text-align: center;
  }

  .mp-user {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 32px;
  }

  .you-will-get-card {
    max-width: 340px;
    padding: 16px;
    margin: 10px auto;
  }

  .you-will-get-card h1 {
    font-size: 16px;
  }

  .you-will-get-card h2 {
    font-size: 20px;
  }

  .you-will-get-card p {
  }

  .you-will-get-card img {
    width: 28px;
    height: 28px;
    position: absolute;
    left: -15px;
    top: 28%;
  }
  .code-box-container {
    max-width: 340px;
  }
  .code-input-container input {
    font-size: 14px;
  }
  .timer-container {
    width: 140px;
    padding: 12px 16px;
    margin: 10px auto 20px;
  }

  .timer-container p {
    font-size: 18px;
  }
  .timer-container h2 {
    font-size: 18px;
    /* width: 110%; */
  }
}
