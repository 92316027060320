/* #33a9bb */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
.Error {
  color: red;
  min-height: 24px;
  margin: 0;
  max-width: 400px;
  font-size: 14px;
  margin: auto;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.Success {
  /* color: red; */
  height: 24px;
  margin: 0;
}

.HoverDoosraEffect {
  cursor: pointer;
  color: #ed3833;
}

.PlainHover {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.Button:active,
.Button:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.Red {
  color: red;
}

.Green {
  color: #53b416;
}

.Grey {
  opacity: 0.5;
}

.LeftBox {
  float: right;
}

.MiddleBox {
  margin-left: auto;
  margin-right: auto;
}

.RightBox {
  float: left;
}

.Stepsbar {
  border-bottom: 6px solid black;
  padding-bottom: 22px;
  margin: 0 auto;
  width: 100%;
}

.Step {
  margin-left: 2vw;
  margin-right: 2vw;
  font-family: Montserrat;
  font-size: 1.1vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
}

.CurrentStep {
  /* color: red; */
  font-size: 1.1vw;
  border-bottom: 6px solid #ed3833;
  margin-left: 2vw;
  margin-right: 2vw;
  padding-bottom: 20px;
  /* font-family: Montserrat-SemiBold; */
  font-family: Montserrat;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
}

.PageHeading {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 2.24; */
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding-bottom: 2vw;
}

.Inputs {
  background: transparent;
  border: none;
  /* margin-top: 25px; */
  margin-top: 1.4vh;
  /* padding-top: 25px; */
  padding-bottom: 10px;
  font-size: 26px;
  font-size: 1.6vw;
  border-bottom: 3px solid black;
  outline: none;
  text-align: center;
  /* width: 20%; */
  min-width: 25vw;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.InputTwo {
  margin: 1.4vh 0;
  padding-bottom: 10px;
  font-size: 26px;
  min-width: 25vw;
  background: transparent;
  border: none;
  border-bottom: 3px solid black;
  outline: none;
  text-align: center;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.PaymentButton {
  padding: 10px 70px;
  cursor: pointer;
  margin-top: 20px;
  border: 0;
  background-color: #ed3833;
  border: none;
  margin-bottom: "50px";
}

.PaymentButton:active,
.PaymentButton:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.DiscountApplyButton {
  background-color: black;
  color: white;
  padding: 18px 22px;
  cursor: pointer;
  margin-top: 2vw;
  border: 0;
}

.Button {
  background-color: black;
  color: white;
  padding: 1vw 1vw;
  cursor: pointer;
  margin-top: 2vw;
  border: 0;
}

.invert {
  background-color: white;
  color: black;
  border: none;
}

.ButtonText {
  font-family: Montserrat;
  font-size: 0.8vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.ButtonTextTwo {
  font-family: Montserrat;
  font-size: 1.2vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.09;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.ButtonTypeOne {
  /* width: 180px; */
  width: 10vw;
  margin-top: 0;
}

.ButtonTypeTwo {
  width: 18vw;
}

.ForwardArrowPosition {
  /* margin-left: 50px; */
  margin-left: 3vw;
}

.OptionsInfoContainer {
  width: 14vw;
}

.OptionsInfoContainer h1 {
  cursor: pointer;
  font-family: Montserrat;
  /* font-size: 34px; */
  font-size: 1.6vw;
  font-weight: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
}

.NumberSelectionBorder {
  cursor: pointer;
}

.OptionsInfoContainer h1:hover {
  color: #ed3833;
}

.Disable h1:hover {
  color: #999999;
}

.OptionsInfoContainer h3 {
  height: 22px;
  font-family: Montserrat;
  /* font-size: 18px; */
  font-size: 0.9vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  /* margin-bottom: 35px; */
  margin-bottom: 1.5vw;
}

.DoneOptions {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 25px;
}

.StartsFrom {
  /* width: 50px; */
  width: 2.6vw;
  font-family: Montserrat;
  /* font-size: 18px; */
  font-size: 0.95vw;
  /* margin-right: 10px; */
  margin-right: 0.5vw;
  position: relative;
  top: -3px;
  line-height: 1.11;
}

.INR {
  /* width: 44px; */
  /* height: 29px; */
  height: 1.5vw;
  font-family: Montserrat;
  /* font-size: 24px; */
  font-size: 1.4vw;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  /* margin-right: 10px; */
}

.INR2 {
  font-family: Montserrat;
  font-size: 18px;
  margin-right: 3px;
}

.INRAmount {
  width: 104px;
  width: 6vw;
  height: 66px;
  font-family: Montserrat;
  font-size: 54px;
  font-size: 2.4vw;
  font-weight: 600;
  line-height: 0.67;
  text-align: center;
}

.INRAmount2 {
  font-family: Montserrat;
  font-size: 24px;
}

.DisplayDoosraNumbersTable {
  margin: 0 auto;
}

/* .DisplayDoosraNumbersTable th {
  padding: 0 1vw;
} */

.DisplayDoosraNumbers {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: 600;
  line-height: 1.06;
  color: #ed3833;
  text-align: left;
  /* padding-right: 1vw */
}

.DisplayDoosraNumber {
  font-family: Montserrat;
  /* font-size: 34px; */
  font-size: 2.4vw;
  font-weight: 600;
  line-height: 1.06;
  color: #ed3833;
  cursor: pointer;
  text-align: center;
  padding-right: 1vw;
}

.DoosraNumber {
  font-family: Montserrat;
  /* font-size: 55px; */
  font-size: 3.5vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: center;
  /* color: #ed3833; */
  cursor: pointer;
}

.DisplayDoosraNumbersTableNoColumn {
  /* min-width: 240px; */
  margin: 8px;
  display: block;
  /* background-color: none; */
  /* border: none; */
}

.DisplayDoosraNumbersTablePriceColumn {
  text-align: left;
  min-width: 130px;
  padding: 0;
}

.HeaderOne {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.24;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.HeaderTwo {
  font-family: Montserrat;
  font-size: 26px;
  font-size: 1.52vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
}

.GreyHeaderOne {
  font-family: Montserrat;
  font-size: 16px;
  text-align: center;
  color: #666666;
}

.DropSelectedNumber {
  font-family: Montserrat;
  font-size: 16px;
  cursor: pointer;
  color: #666666;
}

.DropSelectedNumber:hover,
.DropSelectedNumber:active,
.DropSelectedNumber:visited,
.DropSelectedNumber:focus {
  text-decoration: none;
  color: #ed3833;
}

.ResendOTPHolder {
  margin: 0 auto;
  /* max-width: 500px; */
  max-width: 25vw;
  text-align: right;
}

.ResendOTP {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #196cca;
  cursor: pointer;
  /* position: relative; */
  /* left: 9%; */
  /* font-size: 18px; */
  font-size: 1vw;
}

.ForgotPassword {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.69;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  cursor: pointer;
  font-size: 18px;
}

.NumberNotAllottedHeading {
  font-family: Montserrat;
  font-size: 2vw;
  font-weight: bold;
  line-height: 2.24;
  text-align: center;
  color: #000000;
}

.NumberNotAllottedSubHeading {
  font-family: Montserrat;
  font-size: 1.52vw;
  line-height: 1.69;
  color: #000000;
  margin: 0 18%;
}

.NumberNotAllottedImage {
  margin-top: 20px;
  width: 20vw;
}

.DoneOptionContainer {
  display: inline-flex;
  height: 120px;
  width: 370px;
}

.DoneTick {
  float: left;
  margin-top: 10px;
}

.DoneContent {
  float: right;
  padding: 10px;
}

.AlignContentVertically {
  display: inline;
}

.TermsCheckBoxContainer {
  width: 25vw;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.termsnConditions {
  font-size: 1vw;
}

.TermsLink {
  color: #ed3833;
  cursor: pointer;
  /* font-size: 16px; */
  font-size: 1vw;
}

.DiscountCodeContainer {
  /* margin-left: auto; */
  /* margin-right: auto; */
  /* max-width: 500px; */
  height: 50px;
  display: grid;
  margin-bottom: 30px;
  grid-template-columns: auto auto;
}

.DiscountCodeBox {
  border: 1.5px dashed grey;
  padding: 12px 15px;
  /* padding: 1.2vw 1.25vw; */
  margin-right: 10px;
}

.discount-code-box {
  border: 1.5px dashed grey;
  padding: 12px 15px;
  margin-right: 10px;
}

.discount-apply-btn {
  padding: 15px 25px;
  background-color: #000000;
  color: white;
}

.DiscountCodeInput {
  border: none;
  /* font-size: 24px; */
  font-size: 1.3vw;
  width: 250px;
}

.DiscountCodeContainerPage {
  margin-left: auto;
  margin-right: auto;
  max-width: 420px;
}

.DiscountCodeBoxPage {
  border: 1.5px dashed grey;
  /* padding: 23px 25px; */
  padding: 22px;
  margin-right: 10px;
}

.DiscountCodeInputPage {
  border: none;
  /* font-size: 24px; */
  font-size: 1.3vw;
  max-width: 240px;
}

input:focus {
  outline-width: 0;
}

.DiscountCodeCancel {
  width: 20%;
}

.OptionsContainer {
  display: flex;
  flex-direction: row;
}

.OptionHolder {
  width: 33%;
}

.HeaderBottomPadding {
  padding-bottom: 4vw;
}

.CenterContainer {
  text-align: center;
  text-align: -webkit-center;
  margin-top: 8%;
  margin-left: auto;
  margin-right: auto;
}

.CenterContainerForgotPassword {
  text-align: center;
  text-align: -webkit-center;
  margin-top: 6%;
  margin-bottom: 4%;
  margin-left: auto;
  margin-right: auto;
}

.WaitlistHeading {
  width: 596px;
  height: 42px;
  font-family: Montserrat;
  font-size: 34px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
}

.TimerHolder {
  width: 14vw;
  /* margin: 2ch; */
  margin: 1vw auto;
  background-color: #f4f4f4;
  padding: 1vh;
}

.TimerText {
  font-family: Montserrat;
  font-size: 1.4vw;
  margin: 5px;
  color: #666666;
}

.TimerTime {
  /* float: right;
  width: 2vw; */
  font-family: Montserrat;
  font-weight: bold;
  font-size: 1.5vw;
  margin: 5px;
  color: black;
  display: inline-block;
  width: 85px;
}

.DiscountCancelButton {
  max-width: 25px;
  position: relative;
  top: -2px;
  cursor: pointer;
}



.playStoreHolder {
  margin-top: 5vw;
}


.PlayStoreSpacing {
  padding-left: 40px;
}

.SoldOut {
  color: #666666;
  font-size: 25px;
}

.Disable {
  color: #999999;
  cursor: default;
}

.Disable h1 {
  cursor: default;
}

.NotFound {
  font-size: 10vw;
  color: #ed3833;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.NotFoundText {
  font-size: 3vw;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.RedirectLink {
  font-size: 1vw;
  color: #ed3833;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-decoration: underline;
}

.Tick {
  fill: #ed3833;
}

.UpgradeFromFree {
  font-size: 24px;
  font-family: Montserrat;
  font-weight: bold;
  font-stretch: normal;
  font-style: bold;
  letter-spacing: normal;
  /* text-decoration: underline; */
  color: black;
}

.FreeTrialPaymentNote {
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.ChangeNumber {
  color: #196cca;
  cursor: pointer;
  text-decoration: underline;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.PasswordCheckContainer {
  background-color: #f2f2f2;
  width: 400px;
  min-width: 25vw;
  margin: 20px auto;
  padding: 20px;
}

.PasswordCheckContainer h1 {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: bold;
  letter-spacing: normal;
  text-align: left;
}

.PasswordCheckContentContainer {
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: auto auto auto auto;
}

.PasswordCheckContentContainer p {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.PasswordCheckContentContainer img {
  width: 18px;
}

.YouWillGetContainer {
  width: 420px;
  margin: 5px auto;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border: solid 0.3px #d1d3d4;
  background-image: linear-gradient(to bottom, #fcfcfc, #fcfcfc);
  position: relative;
}

.YouWillGetContainer h1 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 20px 0 20px 20px;
  margin: 0;
}

.YouWillGetContainer h2 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  padding-top: 20px;
  margin: 0;
}

.YouWillGetContainer h3 {
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.YouWillGetContainer img {
  width: 30px;
  position: absolute;
  top: 23px;
  left: -15px;
}

.upgradeforTenPrecentDiscount {
  color: #53b416;
  font-size: 16px;
  text-decoration: underline;
  text-decoration-color: #53b416;
}

.UpgradeTo12month {
  color: #ed3833;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-decoration: underline;
}

.YouWillGetContainerOne img {
  top: 35px;
}

.ProratedMessage {
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}

.RegisterPageOneOkButton {
  margin: 20px;
}

.OTPResent {
  display: flex;
  justify-content: center;
}
.OTPResent img {
  height: 14px;
  width: 14px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.OTPResent p {
  color: green;
  margin: 0;
  font-size: 14px;
}

@media screen and (max-width: 1600px) {
  .Step {
    margin-left: 25px;
    margin-right: 25px;
  }
  .CurrentStep {
    margin-left: 20px;
    margin-right: 20px;
  }
  .TimerTime {
    width: 50px;
    font-size: 18px;
  }
  .DisplayDoosraNumber {
    font-size: 34px;
  }
}

@media screen and (max-width: 1300px) {
  .CurrentStep {
    font-size: 18px;
  }
  .playStoreHolder {
    margin-top: 5vw;
  }
  .PlayStoreSpacing {
    padding-left: 0;
  }
  .Stepsbar {
    width: 100%;
  }
  .Step {
    display: none;
  }
  .PageHeading {
    font-size: 26px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5;
  }
  .HeaderOne {
    font-size: 26px;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5;
  }
  .HeaderTwo {
    font-size: 18px;
    /* padding-left: 10%; */
    /* padding-right: 10%; */
    line-height: 1.5;
  }
  .DoosraNumber {
    font-size: 48px;
  }
  .Inputs {
    font-size: 20px;
  }
  .TimerHolder {
    width: 150px;
    margin: 5px auto;
    padding: 5px;
  }
  .TimerText {
    font-size: 18px;
  }
  .TimerTime {
    width: 50px;
    font-size: 18px;
  }
  .NumberNotAllottedHeading {
    font-size: 3vw;
    padding-left: 10%;
    padding-right: 10%;
    line-height: 1.5;
  }
  .NumberNotAllottedSubHeading {
    font-size: 2vw;
    /* padding-left: 10%; */
    /* padding-right: 10%; */
    line-height: 1.5;
    margin: 0 10%;
  }
  .NumberNotAllottedImage {
    margin-top: 20px;
    width: 40vw;
  }
  .DisplayDoosraNumbers {
    font-size: 3vw;
  }
  .NotFound {
    font-size: 20vw;
  }
  .NotFoundText {
    font-size: 5vw;
  }
  .RedirectLink {
    font-size: 2vw;
  }
  .ResetPasswordHolder {
    max-width: 333px;
  }
  .ButtonTextTwo {
    font-size: 18px;
  }
}

@media screen and (max-width: 1024px) {
  .ResendOTPHolder {
    max-width: 265px;
  }
  .ResetPasswordHolder {
    max-width: 333px;
  }
  .ResendOTP {
    font-size: 14px;
  }
  .ButtonTypeOne {
    width: 90px;
  }
  .ButtonText {
    font-family: Montserrat;
    font-size: 1.4vw;
  }
  .ButtonTypeTwo {
    width: fit-content;
    padding: 2%;
  }
  .invert {
    width: 124px;
  }
  .TermsCheckBoxContainer {
    width: 265px;
  }
  .termsnConditions {
    font-size: 14px;
  }
  .TermsLink {
    font-size: 14px;
  }
  .OptionsContainer {
    flex-direction: column;
  }
  .OptionHolder {
    width: 100%;
  }
  .LeftBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .MiddleBox {
    margin-left: auto;
    margin-right: auto;
  }
  .RightBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 900px) {
  .OptionsInfoContainer {
    width: 280px;
  }
  .OptionsInfoContainer h1 {
    font-size: 24px;
  }
  .OptionsInfoContainer h3 {
    height: 16px;
  }
  .NumberSelectionBorder {
    /* border: 1px solid black; */
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.55);
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px 5px;
    cursor: pointer;
  }
  .LeftBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .MiddleBox {
    margin-left: auto;
    margin-right: auto;
  }
  .RightBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .StartsFrom {
    font-size: 14px;
    top: -3px;
    margin-right: 25px;
  }
  .INR {
    /* width: 20px; */
    height: 29px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }
  .INRAmount {
    width: 104px;
    height: 40px;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
  }
}

@media screen and (max-width: 1000px) {
.Stepsbar {
  margin-top: 36px;
}
}

@media screen and (max-width: 600px) {
  .CenterContainer {
    margin-top: 45%;
    width: 80%;
  }
  .CenterContainerForgotPassword {
    margin-top: 45%;
    width: 80%;
  }
  .Stepsbar {
    width: 100%;
  }
  .Step {
    display: none;
  }
  .Inputs {
    min-width: 0;
    width: 90%;
  }
  .TermsCheckBoxContainer {
    width: 100%;
  }
  .ResendOTPHolder {
    max-width: 90%;
  }
  .ResetPasswordHolder {
    max-width: 100%;
  }
  .ResendOTP {
    left: 30%;
  }
  .invert {
    margin: 0;
  }
  .WaitlistHeading {
    font-size: 24px;
    height: 70px;
    width: 280px;
  }
  .OptionsInfoContainer {
    width: 280px;
  }
  .OptionsInfoContainer h1 {
    font-size: 24px;
  }
  .OptionsInfoContainer h3 {
    height: 16px;
  }
  .MobileNextLine {
    /* white-space: nowrap; */
    display: inline-block;
    width: 100%;
  }
  /* .AlignContentVertically {
    display: inline-flex;
    flex-direction: column;
  } */
  .NumberSelectionBorder {
    /* border: 1px solid black; */
    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.55);
    border-radius: 10px;
    margin: 20px 0;
    padding: 20px 5px;
    cursor: pointer;
  }
  .DoneOptions {
    font-size: 18px;
  }
  .Tick {
    width: 40px;
    fill: #ed3833;
  }
  .TermsCheckBoxContainer {
    white-space: nowrap;
    padding-left: 5%;
  }
  .DoosraNumber {
    font-size: 36px;
  }
  .DiscountApplyButton {
    padding: 15px 25px;
    width: 30%;
    margin: 20px;
  }
  .Button {
    padding: 15px 25px;
    width: 30%;
    margin: 20px;
  }
  .ButtonTypeOne {
    width: 180px;
  }
  .ButtonTypeTwo {
    width: 295px;
    margin: 0 auto;
  }
  .ForwardArrowPosition {
    margin-left: 50px;
    /* margin-left: 3vw; */
  }
  .ForwardArrowPositionSelectNumber {
    margin-left: 30px;
  }
  .ButtonText {
    font-size: 14px;
  }
  .OptionsContainer {
    flex-direction: column;
  }
  .OptionHolder {
    width: 100%;
  }
  .DoneOptionContainer {
    width: 100%;
    height: 80px;
    padding: 4%;
  }
  .LeftBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .MiddleBox {
    margin-left: auto;
    margin-right: auto;
  }
  .RightBox {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
  .StartsFrom {
    font-size: 14px;
    top: -3px;
    margin-right: 35px;
  }
  .INR {
    /* width: 20px; */
    height: 29px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
  }
  .INRAmount {
    width: 104px;
    height: 40px;
    font-family: Montserrat;
    font-size: 36px;
    font-weight: 600;
  }
  .HeaderBottomPadding {
    /* padding-bottom: 20px; */
    padding-bottom: 1.2vw;
  }
  .InputTwo {
    min-width: 0;
    width: 100%;
    font-size: 18px;
  }
  .DiscountCodeContainer {
    padding-top: 10px;
    margin-bottom: 10px;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    height: fit-content;
  }
  .DiscountCodeBox {
    margin: 20px;
    padding: 10px 15px;
    margin-top: 50px;
    max-width: 400px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .discount-code-box {
    border: 1.5px dashed grey;
    padding: 12px 15px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .discount-apply-btn {
    padding: 15px 25px;
  }

  .DiscountCodeInput {
    font-size: 18px;
    width: 200px;
  }
  .DiscountCodeContainerPage {
    padding-top: 25px;
  }
  .DiscountCodeBoxPage {
    margin: 20px;
    padding: 10px 15px;
    margin-top: 50px;
    max-width: 400px;
    margin-left: 10%;
    margin-right: 10%;
  }
  .DiscountCodeInputPage {
    max-width: 200px;
    font-size: 18px;
  }
  .DiscountCancelButton {
    max-width: 25px;
    position: relative;
    top: -1px;
  }
  .NumberNotAllottedHeading {
    font-size: 26px;
  }
  .NumberNotAllottedSubHeading {
    font-size: 18px;
    margin: 0 5%;
  }
  .NumberNotAllottedImage {
    margin: 20px;
    width: 60%;
  }
  .DisplayDoosraNumbersTableNoColumn {
    min-width: 190px;
    margin: 5px;
    display: block;
    background-color: none;
    border: none;
  }
  .DisplayDoosraNumbersTablePriceColumn {
    min-width: 130;
    padding: 0;
  }
  .DisplayDoosraNumbersTable tr {
    margin: none;
    border: none;
    background: none;
    display: revert;
  }
  .DisplayDoosraNumbers {
    font-size: 26px;
  }
  .NotFound {
    font-size: 40vw;
  }
  .NotFoundText {
    font-size: 10vw;
  }
  .RedirectLink {
    font-size: 4vw;
  }
  .PasswordCheckContainer {
    width: 90%;
    /* min-width: 25vw; */
    margin: 20px auto;
    padding: 20px;
  }
  .PasswordCheckContainer h1 {
    font-size: 16px;
  }
  .PasswordCheckContentContainer p {
    font-size: 14px;
  }
  .YouWillGetContainer {
    width: 300px;
  }
  .YouWillGetContainer h1 {
    font-size: 20px;
  }
  .YouWillGetContainer h2 {
    font-size: 20px;
  }
  .YouWillGetContainer h3 {
    font-size: 20px;
  }
  .YouWillGetContainer img {
    width: 30px;
    position: absolute;
    top: 20px;
    left: -15px;
  }
  .upgradeforTenPrecentDiscount {
    font-size: 14px;
  }
  .UpgradeTo12month {
    font-size: 14px;
  }
  .YouWillGetContainerOne img {
    top: 28px;
  }
  .DoneTick {
    float: left;
    margin-top: 0px;
  }
}
