@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700&display=swap");
.Navbar {
  width: 100%;
  background-color: transparent;
  height: 95px;
  list-style-type: none;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.NavbarButton {
  background-color: black;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  color: white;
display: flex;
justify-content: center;
align-items: center;
  width: 144px;
  height: 52px;
      border-radius: 12px;
    background-color: #ed3833;
  margin-right: 4vw;
  text-align: center;
  cursor: pointer;
}

.NavbarButton:hover {
  color: #ffffff;
  text-decoration: underline;
}

.Logo {
  width:212px;
  height:60px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LogoBlack {
  padding: 3%;
  width: 20%;
}

.logo {
  width: 80%;
}

.NavbarShadow {
  box-shadow: 0 1px 3px 0 rgba(250, 8, 8, 0.1);
}

@media screen and (max-width: 1000px) {
  .Logo {
    display: block;
    width: 50%;
    height: auto;
    margin: 10px 0;
  }
  .LogoBlack {
    display: block;
    width: 40%;
    height: auto;
  }

  .logo {
    width: 80%;
  }

  .NavbarButton {
    background-color: white;
    font-family: Montserrat;
    font-size: 22px;
    color: black;
  }

}

@media screen and (max-width: 600px) {
  .NavbarButton {
    width: 100px;
  }
}
