.buy-minute-popup{
  width: 584px;
  height: 441px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 0  16px;
  background-color: #fff;
  text-align: center;
}

.buy-minute-popup h1{
  font-size: 31px;
  font-weight: bold;
  margin-bottom: 8px;
}

.buy-minute-popup img{
  cursor: pointer;
}

.buy-minute-popup h2{
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 40px;
  color:#939598;
}

.buy-minute-popup h3{
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}

.buy-minute-popup h4{
  font-size: 22px;
  font-weight: 600;
}

.buy-minute-popup div{
  display: flex;
justify-content: space-around;
align-items: center;
width: 120px;
}

.buy-minute-popup-button{
  margin-top: 42px;
  border: none;
  display: flex;
  background-color: #000000;
  color: #ffffff;
  font-size: 18px;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  width: 448px;
  height: 72px;
  cursor: pointer;
}

.buy-minute-popup-button:disabled{
  background-color: #a8a8a8;
  cursor: default;
}

.close-minute-popup{
  width: 16px;
  height: 16px;
  align-self: flex-end;

}

.close-minute-popup-button{
display: none;
}

.buy-minute-success{
  margin-top: 48px;
  margin-bottom: 43px;
}


@media screen and (max-width: 600px) {

  .buy-minute-popup{
    width: 100%;
    height: 410px;
    padding: 40px 20px 10px  20px;
    border-radius: 18px 18px 0 0;
    
  }

  .close-minute-popup{
display: none;
  }

  .buy-minute-popup h1{
    font-size: 20px;
  }
  

  .buy-minute-popup h2{
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 40px;
  }
  
  .buy-minute-popup h3{
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 20px;
  }
  
  .buy-minute-popup h4{
    font-size: 22px;
    font-weight: 600;
  }
  
  .buy-minute-popup div{
    display: flex;
  justify-content: space-around;
  align-items: center;
  width: 120px;
  }
  
  .buy-minute-popup-button{
    width: 100%;
    height: 56px;
    font-size: 14px;
  }
  
  .buy-minute-popup-button:disabled{
    background-color: #a8a8a8;
    cursor: default;
  }
  

  .close-minute-popup-button{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 56px;
    margin: 16px 0 0;
    font-size: 14px;
    border: solid 1px #d2d3d4;
    background-color: #fff;
  }

  .buy-minute-success{
    margin-bottom: 34px;
  }

}