.modal {
  font-size: 12px;
  border: 1px solid black;
  padding: 5px;
}

.modal>.header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.8);
}

.modal>.content {
  width: 100%;
  padding: 10px 5px;
}

.modal>.actions {
  width: 100%;
  /* padding: 10px 5px; */
  margin: auto;
  text-align: center;
}

.modal>.close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}

.Modal {
  border: 12px solid black;
  background-color: #ffffff;
  padding: 20px 30px;
  margin: 10px;
  max-width: 500px;
  min-height: 400px;
}

.ModalHeading {
  font-family: Montserrat;
  font-size: 21px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  padding: 20px 0;
}

.PlanUpgradeModalHeading {
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  padding: 20px 0;
}

.PlanUpgradeModalSubHeading {
  width: 375px;
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.NoBottomPadding {
  padding-bottom: 0px;
}

.YayDiscount {
  font-family: Montserrat;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #53b416;
  text-align: center;
}

.ModalBody {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.94;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
}

.ModalBodySpacer {
  margin: 45px 0;
}

.OptionBox {
  width: 100%;
  height: 65px;
  padding: 10px 10px;
  margin: 18px 0;
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

.OptionBox img {
  width: 19px;
  margin-left: 10px;
}

.Recommended {
  color: white;
  background-color: red;
  font-size: 8px;
  position: relative;
  top: -20px;
  left: 148px;
}

.WhatYouGet {
  background-color: #f8f8f8;
  /* margin: 0 5%; */
  margin-bottom: 20px;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
  text-align: left;
  padding: 20px 25px 20px 50px;
}

.WhatYouGet h1 {
  text-align: center;
  font-size: 18px;
  font-weight: bold;
}

.WhatYouGet p {
  margin-bottom: 5px;
}

.WhatYouGetGrid {
  display: grid;
  grid-template-columns: 7% 80%;
  grid-template-rows: auto auto auto auto auto;
}

.WhatYouGetGrid img {
  width: 16px;
  position: relative;
  top: 2px;
}

.ItemSelectedBorder {
  border: 5px solid #ed3833;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  /* align-items: center; */
}

.ItemUnselectedBorder {
  border: 5px solid #d5d5d5;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
  /* align-items: center; */
}

.ModalButtonHolder {
  text-align: right;
  padding: inherit;
}

.ModalButtonHolderTwo {
  text-align: right;
  padding: inherit;
  margin: 0 0 20px 0;
}

.PlanUpgradeText {
  /* padding: 0 5%; */
  /* width: 375px; */
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.ModalButton {
  background-color: black;
  color: white;
  padding: 0.75rem 1.25rem;
  border: 0;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
}

.ModalButtonTwo {
  background-color: black;
  color: white;
  padding: 20px 10px;
  border: 0;
  font-family: Montserrat;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  width: 100%;
}

.Invert {
  color: black;
  background-color: white;
}

.ButtonInvert {
  color: black;
  background-color: white;
}

.ModalButton:active,
.ModalButton:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.ModalButtonTwo:active,
.ModalButtonTwo:focus {
  outline-style: none;
  -moz-outline-style: none;
}

.ModalScroll {
  height: 250px;
  overflow: scroll;
}

.ModalScrollLarge {
  max-height: 90vh;
  overflow: scroll;
}

.ModalScrollLarge::-webkit-scrollbar {}

.ModalScrollLarge::-webkit-scrollbar-thumb {}

.ModalScroll::-webkit-scrollbar {
  width: 0.5rem;
}

/* scrollbar track */

.ModalScroll::-webkit-scrollbar-track {
  /* background: #ffffff; */
  /* border: 2px solid black; */
}

/* scrollbar scroll line color*/

.ModalScroll::-webkit-scrollbar-thumb {
  background: #ed3833;
}

.SuccessBox {
  font-size: 18px;
  border: 1px solid #53b416;
  padding: 9px 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.SuccessBoxRightImage {
  margin-right: 20px;
}

.SuccessBoxRightImage img {
  width: 22px;
  position: relative;
  top: -2px;
}

.SuccessBoxLeftImage {
  margin-left: 20px;
}

.SuccessBoxLeftImage img {
  max-width: 18px;
  position: relative;
  top: -2px;
}

.ModalTwo {
  border: 8px solid black;
  background-color: #ffffff;
  /* margin: 10px; */
  width: 500px;
  min-height: 400px;
}

.ModalBodyTwo {
  padding: 0 20px;
}

.HeaderInfoContainer {
  background-color: #ed3833;
  color: #ffffff;
  display: grid;
  grid-template-columns: 20% 50% 30%;
  height: 70px;
}

.InYourCart {
  font-family: Montserrat;
  font-size: 16px;
  /* font-weight: 500; */
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.69; */
  margin-bottom: 0;
  letter-spacing: normal;
  text-align: left;
}

.PlanTypeInCart {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.HeaderInfoCoupon {}

.HeaderInfoAmount {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: right;
}

.HeaderInfoAmount img {
  width: 14px;
}

.ModalTwoTitleContainer {
  padding: 20px 0;
}

.ModalTwoTitle {
  text-align: center;
  font-family: Montserrat;
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  margin: 0;
}

.ModalTwoSubTitle {
  opacity: 0.5;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  margin: 0;
  line-height: 1;
}

.ModalTwoCurentNumber {
  font-family: Montserrat;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.63;
  letter-spacing: normal;
  text-align: center;
  color: #ed3833;
  padding: 25px 0;
  margin-bottom: 0;
}

.NumberContentHolder {
  border: 4px solid #d5d5d5;
  cursor: pointer;
  padding: 25px 0;
  margin: 10px 0;
  text-align: center;
}

.NumberContentHolder:hover {
  border: 4px solid #ed3833;
}

.NumberContentHolder h1 {
  margin: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.NumberContentHolder h2 {
  margin: 0;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.NumberContentHolder img {
  width: 13px;
}

.NumberContentHolderTwo {
  border: 4px solid #d5d5d5;
  padding: 15px 0;
  margin: 10px 0;
  display: grid;
  grid-template-columns: 47% 22% 31%;
  grid-template-areas: "one two counter";
}

.UnavailableHolder {
  border: 4px solid #d5d5d5;
  color: grey;
  cursor: default;
}

.UnavailableHolder:hover {
  border: 4px solid #d5d5d5;
}

.SelectNumberNum {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  padding-left: 20px;
  grid-area: "one";
}

.SelectNumeberPrice {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin: auto 0;
  grid-area: "two";
}

.SelectNumeberPrice img {
  width: 14px;
}

.DontLikeThisnumberContainer {
  padding: 30px 43px 29px;
  margin: 15px 0;
  background-color: #f8f8f8;
  text-align: center;
}

.DontLikeThisnumberContainer h1 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.DontLikeThisnumberContainer h2 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}

.DontLikeThisnumberContainer p {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ed3833;
  cursor: pointer;
  text-decoration: underline;
}

.CheckoutModalSplitHolder {
  margin: 24px 0 20px 5px;
  margin: 5% 5%;
  padding: 20px 24px;
  background-color: #f8f8f8;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  display: grid;
  grid-template-columns: 60% 40%;
  grid-gap: 20px 0;
  grid-template-rows: auto auto;
}

.CheckoutModalTotalHolder {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.5; */
  letter-spacing: normal;
  margin: 5% 10%;
}

.ChooseSpecialNumberOption {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ed3833;
  text-decoration: underline;
}

.CouponNotApplied {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
}

.CouponApplied {
  font-family: Montserrat;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.7;
  letter-spacing: normal;
  text-align: left;
  color: #00b415;
}

.ModalTimerHolder {
  width: 125px;
  /* margin: 2ch; */
  margin: 5px auto;
  background-color: #f4f4f4;
  padding: 5px;
}

.ModalTimerText {
  font-family: Montserrat;
  font-size: 16px;
  margin: 5px;
  color: #666666;
}

.ModalTimerTime {
  /* float: right;
  width: 2vw; */
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  margin: 5px;
  color: black;
  display: inline-block;
}

.ModalPasswordCheckContainer {
  background-color: #f2f2f2;
  /* width: 400px; */
  /* min-width: 25vw; */
  margin: 20px auto;
  padding: 20px;
}

.ModalPasswordCheckContainer h1 {
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.ModalPasswordCheckContentContainer {
  display: grid;
  grid-template-columns: 8% 92%;
  grid-template-rows: auto auto auto auto;
}

.ModalPasswordCheckContentContainer p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  margin-bottom: 0;
}

.ProductSelectCapsuleHolder {
  text-align: center;
  margin: 10px 0 30px 0;
}

.ProductSelectCapsule {
  padding: 7px 20px 6px;
  margin: 10px;
  border-radius: 30px;
  border: solid 2px #d1d3d4;
  background-color: #ffffff;
  cursor: pointer;
}

.ProductSelectCapsuleHeader {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* line-height: 1.17; */
  letter-spacing: normal;
  text-align: center;
  color: #d1d3d4;
}

.ProductSelcetCapsuleHeaderPro {
  font-style: italic;
}

.CapsuleSelected {
  background-color: #ed3833;
  border: solid 2px #ed3833;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
}

.CapsuleSelectedText {
  color: white;
}

.CapsuleSelectedSubText {
  color: black;
}

.ModalPasswordCheckContentContainer img {
  width: 18px;
}

.BuyMinutesModalBody {
  padding: 20px 40px 0px;
}

.ApplyButton {
  margin-top: 0;
}

.RupeeIcon {
  position: relative;
  top: -1px;
}

@media screen and (max-width: 1700px) {
  .ModalScrollLarge {
    height: 90vh;
    overflow: scroll;
  }

  .ModalScrollLarge::-webkit-scrollbar {
    width: 0.5rem;
  }

  .ModalScrollLarge::-webkit-scrollbar-thumb {
    background: #ed3833;
  }
}

@media screen and (max-width: 600px) {
  .Modal {
    border: 12px solid black;
    background-color: #ffffff;
    padding: 20px 30px;
    margin: 10px;
    width: 96%;
    height: 600px;
    overflow: scroll;
  }

  .PlanUpgradeModalHeading {
    font-size: 20px;
    padding: 0;
  }

  .PlanUpgradeModalSubHeading {
    width: 230px;
    font-size: 14px;
  }

  .ItemUnselectedBorder {
    border: 9px solid #d5d5d5;
    font-size: 18px;
  }

  .ItemSelectedBorder {
    border: 9px solid #ed3833;
    font-size: 18px;
  }

  .DiscountCodeBoxTwo {
    margin: auto 0;
    padding: 10px 15px;
  }

  .DiscountCodeInputTwo {
    max-width: 235px;
  }

  .ButtonTwo {
    padding: 15px 25px;
    width: 46%;
    margin: 0px;
    margin-top: 10px;
  }

  .Recommended {
    top: -16px;
    left: 94px;
  }

  .SuccessBox {
    font-size: 12px;
    border: 1px solid #53b416;
    /* padding: 9px 5px; */
  }

  .SuccessBoxRightImage {
    margin-right: 5px;
  }

  .SuccessBoxRightImage img {
    width: 18px;
  }

  .SuccessBoxLeftImage {
    margin-left: 5px;
  }

  .SuccessBoxLeftImage img {
    width: 18px;
  }

  .AdjustOptnForDiscountedPrices {
    font-size: 13px;
  }

  .ModalTwo {
    width: 100%;
    margin: auto;
  }

  .ModalTwoTitle {
    font-size: 20px;
  }

  .ModalTwoSubTitle {
    font-size: 16px;
  }

  .NumberContentHolder h1 {
    font-size: 16px;
  }

  .NumberContentHolder h2 {
    font-size: 16px;
  }

  .SelectNumberNum {
    font-size: 16px;
    margin: auto 0;
  }

  .SelectNumeberPrice {
    font-size: 16px;
  }

  .InYourCart {
    font-size: 12px;
  }

  .PlanTypeInCart {
    font-size: 14px;
  }

  .HeaderInfoAmount {
    font-size: 16px;
  }

  .DontLikeThisnumberContainer {
    padding: 21px;
  }

  .DontLikeThisnumberContainer h1 {
    font-size: 16px;
  }

  .DontLikeThisnumberContainer h2 {
    font-size: 16px;
  }

  .DontLikeThisnumberContainer p {
    font-size: 16px;
  }

  .ModalButtonTwo {
    font-size: 14px;
  }

  .ModalTwoCurentNumber {
    font-size: 32px;
    padding: 10px 0;
  }

  .WhatYouGet {
    margin-bottom: 10px;
    padding: 10px;
    font-size: 12px;
  }

  .WhatYouGetGrid img {
    top: 0px;
  }

  .WhatYouGet h1 {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }

  .WhatYouGet p {
    margin-bottom: 8px;
  }

  .WhatYouGetGrid {
    grid-template-columns: 9% 80%;
  }

  .ProductSelectCapsule {
    padding: 7px 8px 6px;
    margin: 5px;
    border-radius: 30px;
    cursor: pointer;
  }

  .ProductSelectCapsuleHeader {
    font-size: 14px;
  }

  .ModalScrollLarge {
    /* height: 650px; */
    height: 90vh;
    overflow: scroll;
  }

  .ModalScrollLarge::-webkit-scrollbar {
    width: 0.5rem;
  }

  .ModalScrollLarge::-webkit-scrollbar-thumb {
    background: #ed3833;
  }

  .BuyMinutesModalBody {
    padding: 0 20px;
  }

  /* .RupeesIcon {
    width: 15px;
    position: relative;
    top: -1px;
  } */
  .OptionBox img {
    width: 15px;
    margin-left: 10px;
  }

  .ApplyButton {
    width: 100%;
  }
}